import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiAskCourseDetails, apiAskUniverse, apiGetAssmentData, apiGetQuestionsFromPdf, apiGetYoutubeTranscript, apiGetYoutubeVideos, apiNodeServices, apiSpeechSynthesis, apiStripPaymentCheckoutSession, apiUploadFile, getSignedUrlGcloud, uploadToGoogleCloud } from 'services/AiBotService'
import deepParseJson from 'utils/deepParseJson';
import projectData from 'assets/data/projectList.json';
import { getFirestore, collection, getDocs, addDoc, doc, updateDoc, query, where,arrayUnion,getDoc } from '@firebase/firestore/lite';
import app from 'views/gaze/GazeComponents/firebase';
import store from 'store';
import { PERSIST_STORE_NAME, YOUTUBE_KEY ,YOUTUBE_TRANSCRIPT_KEY} from 'constants/app.constant'

import { apiGetAssesment, apiAskSprintStories } from 'services/AiBotService';

import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';

import { act } from 'react-dom/test-utils';
import { setUser } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess, onLoginInSuccess, setUserAuthority, saveDocId } from 'store/auth/sessionSlice'
import axios from 'axios';
import { clearSelectedUsers, setSharedMessageId } from 'views/gaze/Customers/store/dataSlice';
import { apiSignUp } from 'services/AuthService';
import { Notification, hooks, toast } from 'components/ui'
import useUniqueId from 'components/ui/hooks/useUniqueId';
import { v4 as uuidv4 } from 'uuid';
import appConfig from 'configs/app.config';
import { apiCreateStudentAndShare, apiSendCoursesToSusers } from 'services/AuthServicesNew';






export const initialState = {
  id: 1,
  messages: [],
  history: [],
  newHistory: [],
  isMe: false,
  takeTest: false,
  newCardAddedIndex: 0,
  extractedData: [],
  updatedHistory: "",
  userStories: [],
}

const generateRandomId = () => {
  return Math.floor(Math.random() * 1000000);
};

function groupBySubRowId(jsonArray) {
  return jsonArray.reduce((groups, item) => {
    const id = item.id;
    groups[id] = groups[id] || []; // Create an empty array for the group if it doesn't exist
    groups[id].push(item);
    return groups;
  }, {});
}

//onst firestore = getFirestore();


const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
// console.log('persistData12465727656356735675', rawPersistData);
const persistData = deepParseJson(rawPersistData)



//const messgaeId= messageId?messageId:"uzl2dWzZzsZBcByPbvfW";
//  const messageId= persistData?persistData.auth.user.messageId:"flPh56vvPOia5K8wvLbd";
//  console.log("Inside messageId"+messageId)

//const messageId= "flPh56vvPOia5K8wvLbd";
const firestore = getFirestore(app);
const usersCollection = collection(firestore, 'messages');

// Function to merge subRows
function mergeSubRowsChild(subRows) {
  return subRows.reduce((mergedSubRows, subRow) => {
      mergedSubRows.push(subRow);
      return mergedSubRows;
  }, []);
}

function mergeMCQs(data) {
  let mergedMCQs = [];

  // Loop through each question object
  data.forEach(question => {
    // Check if the question object has subRows
    if (question.subRows) {
      // Loop through each subRow
      question.subRows.forEach(subRow => {
        // Check if the subRow has mcqList
        if (subRow.message && subRow.message.mcqList) {
          // Merge mcqList with mergedMCQs array
          mergedMCQs = mergedMCQs.concat(subRow.message.mcqList);
        }
      });
    }
  });

  return mergedMCQs;
}



export const getAssesmemnt = createAsyncThunk('liveProject/getAssesmemnt', async (data) => {


  const response = await apiGetAssesment(data);

//  console.log("Resopone from vraete assee==="+JSON.stringify(response))

 // console.log("Resopone from vraete assee===848645864576"+JSON.stringify(store.getState().liveCodeSlice.assessmentQuestion))

 
  // console.log("Inside api call==========="+response);


  return response.data
})


export const getAssesmemntInCourse = createAsyncThunk('liveProject/getAssesmemntInCourse', async (data) => {


  const response = await apiGetAssesment(data);

//  console.log("Resopone from vraete assee==="+JSON.stringify(response))

 // console.log("Resopone from vraete assee===848645864576"+JSON.stringify(store.getState().liveCodeSlice.assessmentQuestion))

 
  // console.log("Inside api call==========="+response);


  return response.data
})


export const getEvaluationNonStream = createAsyncThunk('liveProject/getEvaluationNonStream', async (data) => {


  const response = await apiGetAssesment(data);



  return response.data
})

export const createStudentAndShare = createAsyncThunk('liveProject/createStudentAndShare', async (data) => {


  const response = await apiCreateStudentAndShare(data);



  return response.data
})

export const getRelatedQuestionAnswers = createAsyncThunk('liveProject/getRelatedQuestionAnswers', async (data) => {


  const response = await apiGetAssesment(data);

//  console.log("Resopone from vraete assee==="+JSON.stringify(response))

 // console.log("Resopone from vraete assee===848645864576"+JSON.stringify(store.getState().liveCodeSlice.assessmentQuestion))

 
  // console.log("Inside api call==========="+response);


  return response.data
})


export const createUserInternalBackup = createAsyncThunk('liveProject/createUserInternal', async (values,{dispatch}) => {
  try {

    console.log("token======1234435"+JSON.stringify(values))
  //  const resp = await apiSignUp(values)
  const auth = getAuth(app);
    const resp = await createUserWithEmailAndPassword(auth, values.email, values.password);
    console.log('User signed up:', JSON.stringify(resp));
  
    const jsonObject = JSON.parse(JSON.stringify(resp));
 //   const messageId = jsonObject.messageId;
 //   const token = jsonObject.messageId;
// console.log("token======1234messageId"+messageId)
// console.log("token======1234user"+jsonObject.user)

    if (jsonObject.user) {
       const { token,providerData,user } = jsonObject
    //    console.log("token======1234677789999"+JSON.stringify(providerData))
     //   console.log("token======1234677789999779798789"+jsonObject)
  //      console.log("token======1234677789999779798789message"+messageId)
 //       console.log("token======1234677789999779798789token"+token)
      //  jsonObject.user.messageId = messageId;
        jsonObject.user.name = values.userName;
        jsonObject.user.role = values.role;
       // dispatch(setDocumentId(messageId));
        dispatch(onSignInSuccess(jsonObject.user))
        dispatch(setDataAfterLogin(jsonObject.user))
        if (jsonObject.user) {
            dispatch(
                setUser(
                    jsonObject.user || {
                        avatar: '',
                        userName: 'Anonymous',
                        authority: ['USER'],
                        email: '',
                    }
                )
            )

          //  console.log("token======1234 Before "+JSON.stringify(jsonObject.user))
//   const db = getFirestore(app);
//  const usersCollection = collection(db, 'messages');

try{
 //   const documentRef = doc(usersCollection,  messageId);
    const documentRef = collection(firestore, 'messages');
    addDoc(documentRef, jsonObject.user);
   console.log('Document successfully updated!');
 } catch (error) {
   console.error('Error updating document:', error);
 }





        }

        toast.push(
            <Notification title="User Created" type="success">
               Successfully Create User Up
            </Notification>,
            {
                placement: 'top-center',
            })
     //   const redirectUrl = query.get(REDIRECT_URL_KEY)

      
    }
} catch (errors) {
    return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
    }
}
})

export const createUserInternal = createAsyncThunk('liveProject/createUserInternal', async (values,{dispatch}) => {
  try {

      console.log("token======1234435"+JSON.stringify(values))
      const db = getFirestore(app)
      const auth = getAuth(app);
    const resp = await createUserWithEmailAndPassword(auth, values.email, values.password);
   //   const resp = await apiSignUp(values)
      console.log("token======12344359999999999999999"+JSON.stringify(values))
     
      const jsonObject = JSON.parse(JSON.stringify(resp));

      
     // const messageId = jsonObject.messageId;
    //  const token = jsonObject.messageId;
// console.log("token======1234messageId"+messageId)
// console.log("token======1234user"+jsonObject.user)

      if (jsonObject.user) {
         const { token,providerData,user } = jsonObject

       //   jsonObject.user.messageId = messageId;
          jsonObject.user.personalData = JSON.stringify(values);
          jsonObject.user.role=values.role;
          jsonObject.user.name=values.name;
     
          if (jsonObject.user) {

            
     
  //const usersCollection = collection(db, 'messages');

  try{
    //  const documentRef = doc(usersCollection,  messageId);
      const documentRef = collection(firestore, 'messages');
      addDoc(documentRef, {})
      .then((docRef) => {
        //  data.messageId = docRef.id;
      
        const userToCreate =   {
          "id":docRef.id,
          "name":values.name,
          "email":jsonObject.user.email,
          "uid":jsonObject.user.uid,
       //   "img":"/img/avatars/thumb-1.jpg",
          "role":values.role,
          "lastOnline":1623430400,
          "status":"Pending",
          "interests":values.interests,
          "personalInfo":store.getState().liveCodeSlice.documentId,
          "sharedData":'',
       
        };
     //   console.log("token======1234 Creating user in messages"+JSON.stringify(jsonObject.user))
   
  
  
  
  
  
      const messageCollection = collection(firestore, 'messages');
      const documentRef = doc(messageCollection, docRef.id);
  //     dataUser.hostInfo=JSON.stringify(store.getState().auth.user);
  
  // const newIdShareMe = useUniqueId('EXAM-')/
  
  
     
 // const stateToPersist = deepParseJson(JSON.stringify(values));

  const stateToPersist = deepParseJson(JSON.stringify(userToCreate));
  
   //  console.log("Selected rows published==="+JSON.stringify(stateToPersist));
  
   
   updateDoc(documentRef, stateToPersist);
   dispatch(addItem(userToCreate))
  //  updateDoc(documentRef,{
  //      ["projectList"]: arrayUnion(stateToPersist),
  //    });
    

      
      
        });


     console.log('Document successfully updated!');
   } catch (error) {
     console.error('Error updating document:', error);
   }


          }


         
      }
  } catch (errors) {
      return {
          status: 'failed',
          message: errors?.response?.data?.message || errors.toString(),
      }
  }
})





export const shareSubTopics = createAsyncThunk('liveProject/shareSubTopics', async (data,{ dispatch }) => {

  var row = data;

 
   
 
  const updatedDataTemp = store.getState().liveCodeSlice.questions.filter((item) => item.id === row.original.id);

  const data1={ "userStories": updatedDataTemp, "messages": []};

 // console.log("Data to share====="+JSON.stringify(data));
  
  dispatch(getSharedData(data1));

  //console.log("in side remode reducers====" + JSON.stringify(updatedDataTemp))
 // state.questions = updatedDataTemp;


 // return response.data
})


export const getSpeechSynthesize = createAsyncThunk('liveProject/getSpeechSynthesize', async (data) => {
  

  const   response = await apiSpeechSynthesis(data)

  console.log("Respponsse friwejjfj==="+JSON.stringify(response))

  return response.data.url;
})

export const uploadCanvasGclodPrsigned = createAsyncThunk('liveProject/uploadCanvasGclodPrsigned', async (data,{ dispatch }) => {
  

   const   response = await uploadToGoogleCloud(data)

   console.log("Respponsse friwejjfj=898888988988=="+JSON.stringify(response))

  // const dataToPass = {
  //   "file": data.file, // Pass the Blob object as the file
  //   "presignedUrl": response.presignedUrl,
  // };

  //dispatch(uploadToGoogleCloud(data))

  return response;
})


export const getStudentPerformance = createAsyncThunk('liveProject/getStudentPerformance', async (data,{ dispatch }) => {
  

  const   response = await apiGetAssmentData(data)

  console.log("Respponsse friwejjfj=898888988988=="+JSON.stringify(response))

 // const dataToPass = {
 //   "file": data.file, // Pass the Blob object as the file
 //   "presignedUrl": response.presignedUrl,
 // };

 //dispatch(uploadToGoogleCloud(data))

 return response.data;
})

export const generatePresignedUrl = createAsyncThunk('liveProject/generatePresignedUrl', async (data,{ dispatch }) => {
  

  const   response = await getSignedUrlGcloud(appConfig.preSignedUrl)

  console.log("Respponsse friwejjfj==="+JSON.stringify(response))

  // const dataToPass = {
  //   "file": data.file, // Pass the Blob object as the file
  //   "presignedUrl": response.presignedUrl,
  // };

  // dispatch(uploadToGoogleCloud(dataToPass))

  return response.data.presignedUrl;
})




export const getSharedDataCourse = createAsyncThunk('liveProject/getSharedDataCourse', async (data,{ dispatch }) => {

  //console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
  const sharedMessagesCollection = collection(firestore, 'sharedMessages');
  
    const response= await addDoc(sharedMessagesCollection,data)
    
    .then((docRef) => {
    //  data.messageId = docRef.id;
  
      const urlParams = new URLSearchParams();
      const urlParams1 = new URLSearchParams();
    //  console.log("shared data gjhggjkhgjg====="+JSON.stringify(docRef.id));
      urlParams.set('sharedMessages', JSON.stringify(docRef.id)+'&'+store.getState().liveCodeSlice.documentId);
   //   console.log("shared data gjhggjkhgjg====="+urlParams.toString());
    //  urlParams1.set('hostId', store.getState().liveCodeSlice.documentId);
   //   const shareUrl = `${window.location.origin}/app/aiTutor?${urlParams.toString()}&${urlParams1.toString()}`;
  
      const shareUrl =(data.type && data.type==='Course')? `${window.location.origin}/app/aiTutor?${urlParams.toString()}`:`${window.location.origin}/app/takeTest?${urlParams.toString()}`;
  
      dispatch(setSharedUrl(shareUrl));
      dispatch(setSharedId(docRef.id))
      dispatch(setContenType(data.type));
      dispatch(setSharedMessageId(docRef.id))
    
     // Fetch all users and set shared id dispatch(getUsersFromFirebase(docRef.id))
  
  
     // return shareUrl;
  
  
    });
   
  
    
   //console.log('Document successfully updated!'+shareUrl);
  
  
  return response.data
  })
  

  export const afterSignup = createAsyncThunk('liveProject/afterSignup', async (data,{ dispatch }) => {

    //console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
    const sharedMessagesCollection = collection(firestore, 'messages');
  //  const documentRef = doc(sharedMessagesCollection, 'dq1FWv90gLyX7yMwQCao');
    
    //const data={ "userStories": questions, "messages": messages};
    
    //dispatch(clearSelectedUsers());
    
    //   console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
     //const stateToPersist = deepParseJson(JSON.stringify(data));
     
     //const usersCollection = collection(db, 'messages');
      const response= await addDoc(sharedMessagesCollection,{})
      
      .then((docRef) => {
      //  data.messageId = docRef.id;
    
      const values = {
        id:'Course-'+ docRef.id,
        name: data.title,
        desc: data.content,
        messages:[],
        userStory:[],
        type:'Course'
     //   totalTask,
     //   completedTask,
     //   courseId: (projectList[projectList.length-1])?projectList[projectList.length-1].courseId:0,
     //   courseId: (state.projectList)?(projectList.length):0,
      //  progression: (completedTask / totalTask) * 100 || 0,
      //  member,
    }

  //  console.log("formValue after submitting before1111", values);
  //  dispatch(updateCourseId(values.courseId));

  //  console.log("formValue after submitting before2222", formValue);
    dispatch(putProject(values))





    const messageCollection = collection(firestore, 'messages');
    const documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
//     dataUser.hostInfo=JSON.stringify(store.getState().auth.user);

// const newIdShareMe = useUniqueId('EXAM-')


   
const stateToPersist = deepParseJson(JSON.stringify(values));

 //  console.log("Selected rows published==="+JSON.stringify(stateToPersist));

 

 updateDoc(documentRef,{
     ["projectList"]: arrayUnion(stateToPersist),
   });
  










        // dispatch(setSharedUrl(shareUrl));
        // dispatch(setSharedId(docRef.id))
        // dispatch(setContenType(data.type));
        // dispatch(setSharedMessageId(docRef.id))
      
       // Fetch all users and set shared id dispatch(getUsersFromFirebase(docRef.id))
    
    
       // return shareUrl;
    
    
      });
     
    
      
     //console.log('Document successfully updated!'+shareUrl);
    
    
    return response.data
    }) 



  export const createProject = createAsyncThunk('liveProject/createProject', async (data,{ dispatch }) => {

    //console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
    const sharedMessagesCollection = collection(firestore, 'sharedMessages');
  //  const documentRef = doc(sharedMessagesCollection, 'dq1FWv90gLyX7yMwQCao');
    
    //const data={ "userStories": questions, "messages": messages};
    
    //dispatch(clearSelectedUsers());
    
    //   console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
     //const stateToPersist = deepParseJson(JSON.stringify(data));
     
     //const usersCollection = collection(db, 'messages');
      const response= await addDoc(sharedMessagesCollection,{})
      
      .then((docRef) => {
      //  data.messageId = docRef.id;
    
      const values = {
        id:data.type+'-'+ docRef.id,
        name: data.title,
        desc: data.content,
        messages:[],
        type:data.type,
        userStory:[],
        
     //   totalTask,
     //   completedTask,
     //   courseId: (projectList[projectList.length-1])?projectList[projectList.length-1].courseId:0,
     //   courseId: (state.projectList)?(projectList.length):0,
      //  progression: (completedTask / totalTask) * 100 || 0,
      //  member,
    }

  //  console.log("formValue after submitting before1111", values);
  //  dispatch(updateCourseId(values.courseId));

  //  console.log("formValue after submitting before2222", formValue);
    dispatch(putProject(values))





    const messageCollection = collection(firestore, 'messages');
    const documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
//     dataUser.hostInfo=JSON.stringify(store.getState().auth.user);

// const newIdShareMe = useUniqueId('EXAM-')


   
const stateToPersist = deepParseJson(JSON.stringify(values));

 //  console.log("Selected rows published==="+JSON.stringify(stateToPersist));

 

 updateDoc(documentRef,{
     ["projectList"]: arrayUnion(stateToPersist),
   });
  










        // dispatch(setSharedUrl(shareUrl));
        // dispatch(setSharedId(docRef.id))
        // dispatch(setContenType(data.type));
        // dispatch(setSharedMessageId(docRef.id))
      
       // Fetch all users and set shared id dispatch(getUsersFromFirebase(docRef.id))
    
    
       // return shareUrl;
    
    
      });
     
    
      
     //console.log('Document successfully updated!'+shareUrl);
    
    
    return response.data
    }) 


    export const createAssessment = createAsyncThunk('liveProject/createAssessment', async (data,{ dispatch }) => {

      //console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
      const sharedMessagesCollection = collection(firestore, 'sharedMessages');
    //  const documentRef = doc(sharedMessagesCollection, 'dq1FWv90gLyX7yMwQCao');
      
      //const data={ "userStories": questions, "messages": messages};
      
      //dispatch(clearSelectedUsers());
      
      //   console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
       //const stateToPersist = deepParseJson(JSON.stringify(data));
       
       //const usersCollection = collection(db, 'messages');
        const response= await addDoc(sharedMessagesCollection,{})
        
        .then((docRef) => {
        //  data.messageId = docRef.id;
      
        const values = {
          id:'Test-'+ docRef.id,
          name: data.title,
          desc: data.content,
          messages:[],
          userStory:[],
          type:'Test'
       //   totalTask,
       //   completedTask,
       //   courseId: (projectList[projectList.length-1])?projectList[projectList.length-1].courseId:0,
       //   courseId: (state.projectList)?(projectList.length):0,
        //  progression: (completedTask / totalTask) * 100 || 0,
        //  member,
      }
  
    //  console.log("formValue after submitting before1111", values);
    //  dispatch(updateCourseId(values.courseId));
  
    //  console.log("formValue after submitting before2222", formValue);
      dispatch(putProject(values))
  
  
  
  
  
      const messageCollection = collection(firestore, 'messages');
      const documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
  //     dataUser.hostInfo=JSON.stringify(store.getState().auth.user);
  
  // const newIdShareMe = useUniqueId('EXAM-')
  
  
     
  const stateToPersist = deepParseJson(JSON.stringify(values));
  
   //  console.log("Selected rows published==="+JSON.stringify(stateToPersist));
  
   
  
   updateDoc(documentRef,{
       ["projectList"]: arrayUnion(stateToPersist),
     });
    
  
  
  
  
  
  
  
  
  
  
          // dispatch(setSharedUrl(shareUrl));
          // dispatch(setSharedId(docRef.id))
          // dispatch(setContenType(data.type));
          // dispatch(setSharedMessageId(docRef.id))
        
         // Fetch all users and set shared id dispatch(getUsersFromFirebase(docRef.id))
      
      
         // return shareUrl;
      
      
        });
       
      
        
       //console.log('Document successfully updated!'+shareUrl);
      
      
      return response.data
      }) 


export const getSharedData = createAsyncThunk('liveProject/getSharedData', async (data,{ dispatch }) => {

//console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
const sharedMessagesCollection = collection(firestore, 'sharedMessages');
const documentRef = doc(sharedMessagesCollection, 'dq1FWv90gLyX7yMwQCao');

//const data={ "userStories": questions, "messages": messages};

//dispatch(clearSelectedUsers());

//   console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
 //const stateToPersist = deepParseJson(JSON.stringify(data));
 
 //const usersCollection = collection(db, 'messages');
  const response= await addDoc(sharedMessagesCollection,data)
  
  .then((docRef) => {
  //  data.messageId = docRef.id;

    const urlParams = new URLSearchParams();
    const urlParams1 = new URLSearchParams();
    console.log("shared data gjhggjkhgjg====="+JSON.stringify(docRef.id));
    urlParams.set('sharedMessages', JSON.stringify(docRef.id)+'&'+store.getState().liveCodeSlice.documentId);
    console.log("shared data gjhggjkhgjg====="+urlParams.toString());
  //  urlParams1.set('hostId', store.getState().liveCodeSlice.documentId);
 //   const shareUrl = `${window.location.origin}/app/aiTutor?${urlParams.toString()}&${urlParams1.toString()}`;

    const shareUrl =(data.type && data.type==='Course')? `${window.location.origin}/app/aiTutor?${urlParams.toString()}`:`${window.location.origin}/app/takeTest?${urlParams.toString()}`;

    dispatch(setSharedUrl(shareUrl));
    dispatch(setSharedId(docRef.id))
    dispatch(setContenType(data.type));
    dispatch(setSharedMessageId(docRef.id))
  
   // Fetch all users and set shared id dispatch(getUsersFromFirebase(docRef.id))


   // return shareUrl;


  });
 

  
 //console.log('Document successfully updated!'+shareUrl);


return response.data
})




export const updateEvaluatedResult = createAsyncThunk('liveProject/updateEvaluatedResult', async (hostId,{ dispatch }) => {

  //console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);


  
  const sharedMessagesCollection = collection(firestore, 'sharedMessages');
  const documentRef = doc(sharedMessagesCollection, 'dq1FWv90gLyX7yMwQCao');
  
  
  const data={ "userStories": store.getState().liveCodeSlice.questions, "messages": store.getState().liveCodeSlice.messages,"testResult": store.getState().liveCodeSlice.yourArray,"score": store.getState().liveCodeSlice.scoreTopic};
   //  console.log('Document added with ID:= '+data,receivedData);
   
   //const stateToPersist = deepParseJson(JSON.stringify(data));
   
   //const usersCollection = collection(db, 'messages');
   // const response= await updateDoc(sharedMessagesCollection,data,receivedData)
    const response= await addDoc(sharedMessagesCollection,data)

 //   const documentRef = doc(usersCollection, state.documentId);
   
    
    .then((docRef) => {
    //  data.messageId = docRef.id;
  
      const urlParams = new URLSearchParams();
      console.log("shared data gjhggjkhgjg=====2222 host ideddddd"+JSON.stringify(hostId));
      urlParams.set('sharedMessages', docRef.id);
      const receivedData =   {
        "id":hostId,
        "name":store.getState().auth.session.token.email,
        "email":store.getState().auth.session.token.email,
        "img":"/img/avatars/thumb-1.jpg",
        "role":"Admin",
        "lastOnline":1623430400,
        "status":"Test",
        "contentName":store.getState().liveCodeSlice.testName,
        "score":store.getState().liveCodeSlice.scoreTopic,
        "personalInfo":store.getState().liveCodeSlice.documentId,
        "sharedData":docRef.id,
        "senderData":store.getState().auth.user
     
      };



     // receivedData.sharedData=docRef.id;
    //  urlParams.set('hostId', store.getState().liveCodeSlice.documentId);
   //   const shareUrl = `${window.location.origin}/app/aiTutor?${urlParams.toString()}`;
      const shareUrl = `${window.location.origin}/app/takeTest?${urlParams.toString()}`;
  
      dispatch(setSharedUrl(shareUrl));
       dispatch(submitEvaluationToTeacher(receivedData));
    //  dispatch(getUsersFromFirebase(docRef.id))

  
     // return shareUrl;
  
  
    });
   
  
    
//   console.log('Document successfully updated!'+response);
  
  
  return response.data
  })



export const getSharedDataSocial = createAsyncThunk('liveProject/getSharedData', async (data,{ dispatch }) => {

  //console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
  const sharedMessagesCollection = collection(firestore, 'sharedMessages');
  const documentRef = doc(sharedMessagesCollection, 'dq1FWv90gLyX7yMwQCao');
  
  
  
  //   console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
   //const stateToPersist = deepParseJson(JSON.stringify(data));
   
   //const usersCollection = collection(db, 'messages');
    const response= await addDoc(sharedMessagesCollection,data)
    
    .then((docRef) => {
    //  data.messageId = docRef.id;
  
      const urlParams = new URLSearchParams();
      console.log("shared data gjhggjkhgjg=====1111111"+JSON.stringify(docRef));
      urlParams.set('sharedMessages', docRef.id);
      const shareUrl = `${window.location.origin}/app/takeTest?${urlParams.toString()}`;
  
      dispatch(setSharedUrl(shareUrl));
   //   dispatch(getUsersFromFirebase(docRef.id))
  console.log("shared data gjhggjkhgjg====="+shareUrl);
  
     // return shareUrl;
  
  
    });
   
  
    
   console.log('Document successfully updated!'+response);
  
  
  return response.data
  })



  export const getProjectData = createAsyncThunk(
    'liveProject/getProjectData',
    async (data, { dispatch }) => {
  
  
  // Create a reference to the specific document
  const docRef = doc(firestore, 'sharedMessages', data);
  
  // Use getDoc to retrieve the document
  getDoc(docRef)
    .then((docSnap) => {
      if (docSnap.exists()) {
        const dataFromFirestore = docSnap.data();
    //    console.log('Document data:', dataFromFirestore);
        dispatch(updateProjectData(dataFromFirestore));
     
      } else {
        console.log('Document does not exist');
      }
    })
    .catch((error) => {
      console.error('Error getting document: ', error);
    });
  
  
  
  
    }
  );

  export const getAssignedProjectData = createAsyncThunk(
    'liveProject/getAssignedProjectData',
    async (data, { dispatch }) => {
  
  
  // Create a reference to the specific document
  const docRef = doc(firestore, 'assignedProject', data);
  
  // Use getDoc to retrieve the document
  getDoc(docRef)
    .then((docSnap) => {
      if (docSnap.exists()) {
        const dataFromFirestore = docSnap.data();
        dispatch(updateProjectData(dataFromFirestore));
     
      } else {
        console.log('Document does not exist');
      }
    })
    .catch((error) => {
      console.error('Error getting document: ', error);
    });
  
  
  
  
    }
  );


  
export const loadSharedStateFromFirebase = createAsyncThunk(
  'liveProject/loadStateFromFirebase',
  async (data, { dispatch }) => {


// Create a reference to the specific document
const docRef = doc(firestore, 'sharedMessages', data);

// Use getDoc to retrieve the document
getDoc(docRef)
  .then((docSnap) => {
    if (docSnap.exists()) {
      const dataFromFirestore = docSnap.data();
      
      // Check if 'messages' and 'userStories' fields exist in the data
      if ('messages' in dataFromFirestore && 'userStories' in dataFromFirestore) {
        const messages = dataFromFirestore.messages;
        const userStories = dataFromFirestore.userStories;
        const type = dataFromFirestore.type;
        const testName = dataFromFirestore.testName;
        const courseName = dataFromFirestore.courseName;
        const testResult = dataFromFirestore.testResult;
        const score = dataFromFirestore.score;
        const courseId = dataFromFirestore.courseId;
        
        
        
        // Use the 'messages' and 'userStories' arrays as needed
      //  console.log('Messages:', messages);
      //  console.log('User Stories:', userStories);
        
        // Dispatch or handle the data as needed
        dispatch(shareLearning({ messages, userStories,type,testName,courseName,data,testResult,score,courseId }));
      } else {
        console.log('One or both fields not found in Firestore data');
      }
    } else {
      console.log('Document does not exist');
    }
  })
  .catch((error) => {
    console.error('Error getting document: ', error);
  });




  }
);




export const loadShredTestFrmServer = createAsyncThunk(
  'liveProject/loadShredTestFrmServer',
  async (data, { dispatch }) => {



    
  //   if(state.projectList && state.projectList.length>0){

  //     const index = state.projectList.findIndex((item) => item.id === values.id);
  //     if(index !== -1){

  //       dispatch(setTestFromLocal({ state.projectList[index].messages, userStories,type,testName,courseName,data,testResult,score }));

  //     }else{
  //      state.projectList=[...state.projectList,values]
  //     }
     
  //  }else{
  //    state.projectList=[values]
  //  }







// Create a reference to the specific document
const docRef = doc(firestore, 'sharedMessages', data);

// Use getDoc to retrieve the document
getDoc(docRef)
  .then((docSnap) => {
    if (docSnap.exists()) {
      const dataFromFirestore = docSnap.data();
      
      // Check if 'messages' and 'userStories' fields exist in the data
      if ('messages' in dataFromFirestore && 'userStories' in dataFromFirestore) {
        const messages = dataFromFirestore.messages;
        const userStories = dataFromFirestore.userStories;
        const type = dataFromFirestore.type;
        const testName = dataFromFirestore.testName;
        const courseName = dataFromFirestore.courseName;
        const testResult = dataFromFirestore.testResult;
        const score = dataFromFirestore.score;
        
        
        
        // Use the 'messages' and 'userStories' arrays as needed
      //  console.log('Messages:', messages);
      //  console.log('User Stories:', userStories);
        
        // Dispatch or handle the data as needed
        dispatch(setTestFromLocal({ messages, userStories,type,testName,courseName,data,testResult,score }));


      } else {
        console.log('One or both fields not found in Firestore data');
      }
    } else {
      console.log('Document does not exist');
    }
  })
  .catch((error) => {
    console.error('Error getting document: ', error);
  });




    // You can also dispatch other actions if needed
  }
);


export const getUsersFromFirebase = createAsyncThunk(
  'liveProject/getUsersFromFirebase',
  async (data, { dispatch }) => {
    //  dispatch(messageRecievedThreadTutor(data));




    //  const docRef = doc(collection(firestore, 'messages'));p
      console.log('Existing data:===========12324325245245=  data:', data);
    //   getDocs(docRef)




    //  const docRef = collection(firestore, 'messages','uzl2dWzZzsZBcByPbvfW');
    // const docRef = collection(firestore, 'messages');

    //  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    //  // console.log('persistData12465727656356735675', rawPersistData);
    //   const persistData = deepParseJson(rawPersistData)
    //console.log('Existing data:==123455666', data);
   // const uid = data.uid;
   // console.log("Inside loadStateFromFirebaseGoogleLogin" + uid);
    //  if(uid){



    //  }else{
    //   uid=data;
    //  }


    const docRef = query(collection(firestore, 'messages'),
    // where('providerData[0].email', 'array-contains', 'testmail.com')
     );
    // console.log('Existing data:', docRef,uid);
    //  const docSnap = await getDocs(docRef);
    getDocs(docRef)
      .then((docSnap) => {
        if (docSnap) {
          const existingData = docSnap;
        //  console.log('Existing data:===========12324325245245=  docsanp', JSON.stringify(data));

          if (existingData.empty) {

            // const db = getFirestore(app);
            // const messageCollection = collection(db, 'messages');
            // const messageObject = deepParseJson(JSON.stringify(data));

          //  state.createdCourses = [...state.createdCourses, parsedObject]







            // addDoc(messageCollection, messageObject)
            //   .then((docRef) => {
            //     data.messageId = docRef.id;
            //     console.log('Existing data:===========12324325245245=====Document added with ID: ', data.id);

            //     messageObject.messageId = docRef.id;
            //     dispatch(setDocumentId(docRef.id));
            //     //  dispatch(onLoginInSuccess(messageObject))
            //     dispatch(
            //       setUser(
            //         messageObject || {
            //           avatar: '',
            //           userName: 'Anonymous',
            //           authority: ['USER'],
            //           email: '',
            //           messageId: ''
            //         }
            //       )
            //     )

            //   //  dispatch(loadStateFromFirebase(data.uid));
            //   })
            //   .catch((error) => {
            //     console.error('Error adding document: ', error);
            //   });


          }
          else {

            existingData.forEach((docSnapshot) => {

              console.log('Existing data:===========12324325245245=  1212', docSnapshot);


              const dataFirestore = docSnapshot.data();
              const documentId = docSnapshot.id; // Get the document ID
              console.log('Existing data:===========12324325245245=====111', documentId);

              // if(messageId === documentId){
              //   console.log('Existing data:===========12324325245245=====', JSON.stringify(data));

           //   dispatch(setDocumentId(documentId));
             // if (dataFirestore.createdCourses) {

              //  dataFirestore.createdCourses = [...dataFirestore.createdCourses, data.messages]

             //   dataFirestore.createdCourses[data.projectList].messages = [];
             //   dataFirestore.createdCourses[data.courseId].messages = [...dataFirestore.createdCourses[dataFirestore.courseId].messages, ...data.messages]
          

                dataFirestore.providerData[dataFirestore.providerData.length] = data;
                const messageCollection = collection(firestore, 'messages');
                const documentRef = doc(messageCollection, docSnapshot.id);


                const userToCreate =   {
                  "id":docSnapshot.id,
                  "name":dataFirestore.email,
                  "email":dataFirestore.email,
                  "img":"/img/avatars/thumb-1.jpg",
                  "role":"Admin",
                  "lastOnline":1623430400,
                  "status":"active",
                  "personalInfo":store.getState().liveCodeSlice.documentId,
                  "sharedData":data,
                  // "paymentMethod":[
                  //   {
                  //     "cardHoldername":"Carolyn Perkins",
                  //     "cardType":"VISA",
                  //     "expMonth":"12",
                  //     "expYear":"25",
                  //     "last4Number":"0392",
                  //     "primary":true
                  //   },
                  //   {
                  //     "cardHoldername":"Carolyn Perkins",
                  //     "cardType":"MASTER",
                  //     "expMonth":"06",
                  //     "expYear":"25",
                  //     "last4Number":"8461",
                  //     "primary":false
                  //   }
                  // ],
                  // "subscription":[
                  //   {
                  //     "plan":"Business board pro",
                  //     "status":"active",
                  //     "billing":"monthly",
                  //     "nextPaymentDate":1639132800,
                  //     "amount":59.9
                  //   }
                  // ]
                };
             
                dispatch(usersToDisplay(userToCreate))

             //   dispatch(sharedByMe(userToCreate));
             

            });

          }



        }
      })
      .catch((error) => {
        console.error('Error getting document: ', error);
      });







    // Perform additional logic here
    // You can also dispatch other actions if needed
  }
);

export const updateImportedCourses = createAsyncThunk(
  'liveProject/updateImportedCourses',
  async (data, { dispatch }) => {


        const messageCollection = collection(firestore, 'sharedMessages');
        const documentRef = doc(messageCollection, store.getState().liveCodeSlice.sharedId);
      // const stateToPersist = deepParseJson(JSON.stringify(data));

       console.log("Selected rows published=== before updating"+JSON.stringify(store.getState().liveCodeSlice.sharedId));

   //   dispatch(setSharedCourses(stateToPersist));
    

  try {
    updateDoc(documentRef, {
      "messages": store.getState().liveCodeSlice.messages,
      "userStories":store.getState().liveCodeSlice.userStories,
      "type":store.getState().liveCodeSlice.type,
      "courseName":store.getState().liveCodeSlice.courseName

    });
  } catch (error) {
    console.error("An error occurred: ", error);
  }

   
    

  }
  );

export const submitEvaluationToTeacher = createAsyncThunk(
  'liveProject/submitEvaluationToTeacher',
  async (data, { dispatch }) => {

    // const userToCreate =   {
    //   "id":documentId,
    //   "name":email,
    //   "email":email,
    //   "img":"/img/avatars/thumb-1.jpg",
    //   "role":"Admin",
    //   "lastOnline":1623430400,
    //   "status":"active",
    //   "personalInfo":token,
    //   "sharedData":"",
   
    // };

    

  
      // if(data && data!=="")
      // {

       // dataUser.personalInfo=data.user;
        const messageCollection = collection(firestore, 'messages');
        const documentRef = doc(messageCollection, data.id);
       const stateToPersist = deepParseJson(JSON.stringify(data));

      // console.log("Selected rows published=== before updating"+JSON.stringify(stateToPersist));

      dispatch(setSharedCourses(stateToPersist));
    

  try {
    updateDoc(documentRef, {
      ["extractedData"]: arrayUnion(stateToPersist),
    });
  } catch (error) {
    console.error("An error occurred: ", error);
  }

    //   console.log("Selected rows published=== before updating000"+JSON.stringify(data.id));
   //   }



      //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
      //  const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
        
       
       
         
     //   console.log('Document successfully updated!');
    

  }
  );


  export const sendSharedCourseUsersInternal = createAsyncThunk(
    'liveProject/sendSharedCourseUsersInternal',
    async (data, { dispatch }) => {
  
      
  
      data.forEach((dataUser) => {
        if(dataUser && dataUser!=="")
        {
  
         // dataUser.personalInfo=data.user;
          const messageCollection = collection(firestore, 'messages');
          const documentRef = doc(messageCollection, dataUser.providerData[0].messageId);
         const stateToPersist = deepParseJson(JSON.stringify(dataUser));
  
       //  console.log("Selected rows published==="+JSON.stringify(stateToPersist));
  
        dispatch(sharedByMe(stateToPersist));
  
          updateDoc(documentRef,{
           ["extractedData"]: arrayUnion(stateToPersist),
         });
        }
  
  
     });
        //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
        //  const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
          
         
         
           
       //   console.log('Document successfully updated!');
      
  
    }
    );

    export const getStripePaymentCheckout = createAsyncThunk('liveProject/getStripePaymentCheckout', async (data) => {


      // if(!store.getState().tutor.state.isLoading){
        const   response = await apiStripPaymentCheckoutSession(data)
        // const locationHeader = response.headers.get('Location');
        // // }
        // console.log("Inside api call" + JSON.stringify(locationHeader));
    
         return response.data
    })


    export const sendSharedCourseUsers = createAsyncThunk('liveProject/sendSharedCourseUsers', async (data,{ dispatch }) => {

        const response = await apiSendCoursesToSusers(data)

      ///  console.log("Inside api call" + JSON.stringify(response));
       // dispatch(clearSelectedUsers());

        return response.data


      
    })
    
    export const sendSharedCourseUsers11 = createAsyncThunk('liveProject/sendSharedCourseUsers', async (data,{ dispatch }) => {

      //console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
   //   const assignedProjectCollection = collection(firestore, 'assignedProject');
    //  const documentRef = doc(sharedMessagesCollection, 'dq1FWv90gLyX7yMwQCao');
  await   data.selectedUsers.forEach((dataUser) => {
    const sharedMessagesCollection = collection(firestore, 'sharedMessages');
    const dataToSend={ "userStory": store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId].userStory, "messages": store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId].messages};
  
    addDoc(sharedMessagesCollection,dataToSend).then((docRef) => {
    

 const values = {
   id:store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId].type+'-'+ docRef.id,
   name: data.title,
   desc: data.content,
   messages:store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId].messages,
   userStory:store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId].userStory,
   type:store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId].type

    }


    const stateToPersist = deepParseJson(JSON.stringify(values));
  
    const messageCollection = collection(firestore, 'messages');
    const documentRef = doc(messageCollection, dataUser.id)
      updateDoc(documentRef, {
        ["projectList"]: arrayUnion(stateToPersist),
      });




    })

    
    dispatch(clearSelectedUsers());

  }

  )


      
    })
        
       //console.log('Document successfully updated!'+shareUrl);
      
      
    //  return response.data
    
  
    export const getChatGPTApiEvaluateData = createAsyncThunk('liveProject/getChatGPTApiEvaluateData', async (data) => {
      //console.log("Inside api call" + data);
    //  let response;
    
     // if(!store.getState().tutor.state.isLoading){
      const   response = await apiAskUniverse(data)
     // }
    
      return response.data
    })



  export const sendSharedCourseUsersBackUp = createAsyncThunk(
    'liveProject/sendSharedCourseUsers',
    async (data, { dispatch }) => {
  
  
  
      data.selectedUsers.forEach((dataUser) => {
      
        if(dataUser && dataUser!=="")
        {
        //  console.log("Selected rows published==="+JSON.stringify(dataUser.id));
         // dataUser.personalInfo=data.user;
          const messageCollection = collection(firestore, 'messages');
          const documentRef = doc(messageCollection, dataUser.id);
    //     dataUser.hostInfo=JSON.stringify(store.getState().auth.user);
  
  // const newIdShareMe = useUniqueId('EXAM-')


         const values = {
          id: `Course-`+generateRandomId(),
          name: dataUser.sharedData.testName,
          desc: dataUser.sharedData.testName,
          questions:dataUser.sharedData.userStories,
          messages:dataUser.sharedData.messages,
          type:dataUser.sharedData.type,
          completedQuestions:[],
          score:'',
          userStory:dataUser.sharedData.type==='Course'?dataUser.sharedData.userStories:[],
          senderData:dataUser.senderData
    
      }
         
      const stateToPersist = deepParseJson(JSON.stringify(values));
  
       //  console.log("Selected rows published==="+JSON.stringify(stateToPersist));
  
        dispatch(sharedByMe(stateToPersist));
  
          updateDoc(documentRef,{
           ["projectList"]: arrayUnion(stateToPersist),
         });
        }
  
  
     });
        //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
        //  const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
          
         
         
           
       //   console.log('Document successfully updated!');
      
  
    }
    );






    export const loadProjectListOfStudents = createAsyncThunk(
      'liveProject/loadProjectListOfStudents',
      async (data, { dispatch }) => {
    
        const docRef = doc(collection(firestore, 'messages'), data);
        
        try {
          const docSnap = await getDoc(docRef);
    
          if (docSnap.exists()) {
            const documentData = docSnap.data();
            const documentId = docSnap.id;
    
            console.log('Document data:', JSON.stringify(documentData));
            console.log('Document ID:', documentId);
    
            const projectList = documentData.projectList;
    
            console.log('Project List:', projectList);
            dispatch(setStudentsScores(projectList))
    
            // Dispatching actions or performing further logic with projectList
    
            return projectList; // Returning projectList from async thunk
          } else {
            console.log('Document does not exist');
          }
        } catch (error) {
          console.error('Error getting document:', error);
        }
      }
    );
    

// export const loadProjectLisyOfStudents = createAsyncThunk(
//   'liveProject/loadProjectLisyOfStudents',
//   async (data, { dispatch }) => {
  

//     const docRef = query(collection(firestore, 'messages'), where('uid', '==', data));

//     // const messageDoc = await docRef.get();
//     console.log('Existing data:', getDocs(docRef));

//     getDocs(docRef)
//       .then((docSnap) => {
//         if (docSnap) {
//           const existingData = docSnap;
//           existingData.forEach((docSnapshot) => {
//             const data = docSnapshot.data();
//             const documentId = docSnapshot.id; // Get the document ID
//             console.log('Existing data:===========12324325245245=====', documentId);

//             // if(messageId === documentId){
//               console.log('Existing data:===========12324325245245=====', JSON.stringify(data.projectList));

//             dispatch(setDocumentId(documentId));
//             if (data.createdCourses || data.extractedData || data.projectList) {

//               dispatch(loadFromFirestore(data))
//             }

//             // }


//             //   state = data;
//             //  dataArray.push(data);
//           });

//         } else {
//           console.log('Document does not exist');
//         }
//       })
//       .catch((error) => {
//         console.error('Error getting document: ', error);
//       });







//     // Perform additional logic here
//     // You can also dispatch other actions if needed
//   }
// );

export const loadStateFromFirebaseLogin = createAsyncThunk(
  'liveProject/loadStateFromFirebaseLogin',
  async (data, { dispatch }) => {
    //  dispatch(messageRecievedThreadTutor(data));




    //  const docRef = doc(collection(firestore, 'messages'));p
    //  console.log('Existing data:', docRef);
    //   getDocs(docRef)




    //  const docRef = collection(firestore, 'messages','uzl2dWzZzsZBcByPbvfW');
    // const docRef = collection(firestore, 'messages');

    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    // console.log('persistData12465727656356735675', rawPersistData);
    const persistData = deepParseJson(rawPersistData)
    // console.log('Existing data:==123455666', data);
    // let uid = persistData.auth.session.token.uid;

    // if (uid) {



    // } else {
    //   uid = data;
    // }


    const docRef = query(collection(firestore, 'messages'), where('uid', '==', data));

    // const messageDoc = await docRef.get();
 //   console.log('Existing data:', getDocs(docRef));

    getDocs(docRef)
      .then((docSnap) => {
        if (docSnap) {
          const existingData = docSnap;
          existingData.forEach((docSnapshot) => {
            const data = docSnapshot.data();
            const documentId = docSnapshot.id; // Get the document ID
          //  console.log('Existing data:===========12324325245245=====', JSON.stringify(docSnapshot.id));

            // if(messageId === documentId){
            //   console.log('Existing data:===========12324325245245=====', JSON.stringify(data));
            dispatch(setUserAuthority(data.role?data.role:'Admin'));
            dispatch(setDocumentId(documentId));
            dispatch(setDataAfterLogin(data))
            dispatch(saveDocId(documentId));
            // if (data.createdCourses || data.extractedData || data.projectList) {

            //   dispatch(setDataAfterLogin(data))
            // }

            // }


            //   state = data;
            //  dataArray.push(data);
          });

        } else {
          console.log('Document does not exist');
        }
      })
      .catch((error) => {
        console.error('Error getting document: ', error);
      });







    // Perform additional logic here
    // You can also dispatch other actions if needed
  }
);

export const loadProjectListFromFirebase = createAsyncThunk(
  'liveProject/loadProjectListFromFirebase',
  async (data, { dispatch }) => {
    //  dispatch(messageRecievedThreadTutor(data));




    //  const docRef = doc(collection(firestore, 'messages'));p
    //  console.log('Existing data:', docRef);
    //   getDocs(docRef)




    //  const docRef = collection(firestore, 'messages','uzl2dWzZzsZBcByPbvfW');
    // const docRef = collection(firestore, 'messages');

    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    // console.log('persistData12465727656356735675', rawPersistData);
    const persistData = deepParseJson(rawPersistData)
    // console.log('Existing data:==123455666', data);
    // let uid = persistData.auth.session.token.uid;

    // if (uid) {



    // } else {
    //   uid = data;
    // }


    const docRef = query(collection(firestore, 'messages'), where('uid', '==', data));

    // const messageDoc = await docRef.get();
    console.log('Existing data:', getDocs(docRef));

    getDocs(docRef)
      .then((docSnap) => {
        if (docSnap) {
          const existingData = docSnap;
          existingData.forEach((docSnapshot) => {
            const data = docSnapshot.data();
            const documentId = docSnapshot.id; // Get the document ID
            console.log('Existing data:===========12324325245245=====', documentId);

            // if(messageId === documentId){
            //   console.log('Existing data:===========12324325245245=====', JSON.stringify(data));
         //p   dispatch(setUserAuthority(data.role));
            dispatch(setDocumentId(documentId));
            if (data.createdCourses || data.extractedData || data.projectList) {

              dispatch(loadFromFirestore(data))
            }

            // }


            //   state = data;
            //  dataArray.push(data);
          });

        } else {
          console.log('Document does not exist');
        }
      })
      .catch((error) => {
        console.error('Error getting document: ', error);
      });







    // Perform additional logic here
    // You can also dispatch other actions if needed
  }
);


export const loadStateFromFirebase = createAsyncThunk(
  'liveProject/loadStateFromFirebase',
  async (data, { dispatch }) => {
    //  dispatch(messageRecievedThreadTutor(data));




    //  const docRef = doc(collection(firestore, 'messages'));p
    //  console.log('Existing data:', docRef);
    //   getDocs(docRef)




    //  const docRef = collection(firestore, 'messages','uzl2dWzZzsZBcByPbvfW');
    // const docRef = collection(firestore, 'messages');

    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    // console.log('persistData12465727656356735675', rawPersistData);
    const persistData = deepParseJson(rawPersistData)
    // console.log('Existing data:==123455666', data);
    // let uid = persistData.auth.session.token.uid;

    // if (uid) {



    // } else {
    //   uid = data;
    // }


    const docRef = query(collection(firestore, 'messages'), where('uid', '==', data));

    // const messageDoc = await docRef.get();
    console.log('Existing data:', getDocs(docRef));

    getDocs(docRef)
      .then((docSnap) => {
        if (docSnap) {
          const existingData = docSnap;
          existingData.forEach((docSnapshot) => {
            const data = docSnapshot.data();
            const documentId = docSnapshot.id; // Get the document ID
            console.log('Existing data:===========12324325245245=====', documentId);

            // if(messageId === documentId){
            //   console.log('Existing data:===========12324325245245=====', JSON.stringify(data));
         //p   dispatch(setUserAuthority(data.role));
            dispatch(setDocumentId(documentId));
            if (data.projectList) {

              dispatch(loadFromFirestore(data))
            }

            // }


            //   state = data;
            //  dataArray.push(data);
          });

        } else {
          console.log('Document does not exist');
        }
      })
      .catch((error) => {
        console.error('Error getting document: ', error);
      });







    // Perform additional logic here
    // You can also dispatch other actions if needed
  }
);



export const loadStateFromFirebaseGoogleLogin = createAsyncThunk(
  'liveProject/loadStateFromFirebaseGoogleLogin',
  async (data, { dispatch }) => {
    //  dispatch(messageRecievedThreadTutor(data));




    //  const docRef = doc(collection(firestore, 'messages'));p
    //  console.log('Existing data:', docRef);
    //   getDocs(docRef)




    //  const docRef = collection(firestore, 'messages','uzl2dWzZzsZBcByPbvfW');
    // const docRef = collection(firestore, 'messages');

    //  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    //  // console.log('persistData12465727656356735675', rawPersistData);
    //   const persistData = deepParseJson(rawPersistData)
    //console.log('Existing data:==123455666', data);
    const uid = data.uid;
    console.log("Inside loadStateFromFirebaseGoogleLogin" + uid);
    //  if(uid){



    //  }else{
    //   uid=data;
    //  }


    const docRef = query(collection(firestore, 'messages'), where('uid', '==', uid));
    // console.log('Existing data:', docRef,uid);
    //  const docSnap = await getDocs(docRef);
    getDocs(docRef)
      .then((docSnap) => {
        if (docSnap) {
          const existingData = docSnap;
          console.log('Existing data:===========12324325245245=  docsanp', JSON.stringify(data));

          if (existingData.empty) {

            const db = getFirestore(app);
            const messageCollection = collection(db, 'messages');
            const messageObject = deepParseJson(JSON.stringify(data));
            addDoc(messageCollection, messageObject)
              .then((docRef) => {
                data.messageId = docRef.id;
                console.log('Existing data:===========12324325245245=====Document added with ID: ', data.id);

                messageObject.messageId = docRef.id;
                dispatch(setDocumentId(docRef.id));
                //  dispatch(onLoginInSuccess(messageObject))
                dispatch(
                  setUser(
                    messageObject || {
                      avatar: '',
                      userName: 'Anonymous',
                      authority: ['USER'],
                      email: '',
                      messageId: ''
                    }
                  )
                )

                dispatch(loadStateFromFirebase(data.uid));
              })
              .catch((error) => {
                console.error('Error adding document: ', error);
              });


          }
          else {

            existingData.forEach((docSnapshot) => {

              console.log('Existing data:===========12324325245245=  1212', docSnapshot);


              const data = docSnapshot.data();
              const documentId = docSnapshot.id; // Get the document ID
              console.log('Existing data:===========12324325245245=====111', documentId);

              // if(messageId === documentId){
              //   console.log('Existing data:===========12324325245245=====', JSON.stringify(data));

              dispatch(setDocumentId(documentId));
              if (data.createdCourses || data.extractedData || data.projectList) {

                dispatch(loadFromFirestore(data))
              }

              // }


              //   state = data;
              //  dataArray.push(data);
            });

          }



        }
      })
      .catch((error) => {
        console.error('Error getting document: ', error);
      });







    // Perform additional logic here
    // You can also dispatch other actions if needed
  }
);



export const fetchYoutbeVideosApi = createAsyncThunk('liveProject/fetchYoutbeVideosApi',  async (data, { dispatch })=> {
 


       
        const response = await axios.get(
          "https://www.googleapis.com/youtube/v3/search",
          {
            params: {
              part: "snippet",
              q:JSON.stringify(data.original.message), // Replace with your search query
              maxResults: 4, // Number of videos to display
              key:YOUTUBE_KEY , // Replace with your YouTube API key"AIzaSyBoXmH1ft_ql63ie0EI6DoeQsZkT2_ue7k"
            },
          }
        );
       // setVideos(response.data.items);
        dispatch(setVideos({"data":data,"videoYoutube":response.data.items}));

     
      

 // const response = await apiGetYoutubeVideos(data)

 // console.log("Inside api call rewponse=" + JSON.stringify(response.data.items));
  return response.data
})


export const getScoreDataFromFunction = createAsyncThunk('liveProject/getScoreDataFromFunction', async (data) => {
  // console.log("Inside api call"+data);

  const response = await apiNodeServices({
    messageId: data,
  },)
  console.log("Inside api call" + JSON.stringify(response));

  return response.data
})


// export const getScoreDataFromFunction11 = createAsyncThunk('liveProject/getScoreDataFromFunction11', async (data, { dispatch }) => {
//   try {
//     const response = await axios.post(
//       "https://us-central1-solar-icon-418811.cloudfunctions.net/fetchScoreData",
//       {
//         messageId: data,
//       },
//       {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       }
//     );

//     console.log("Inside API call response=" + JSON.stringify(response));
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching score data:", error);
//     throw error;
//   }
// });




export const imageToTextApi = createAsyncThunk('liveProject/imageToTextApi',  async (data, { dispatch })=> {
 


  // const reader = new FileReader(data);
  // reader.onload = (event) => {
  //   const base64String = event.target.result.split(",")[1]; // Remove data URL prefix
  //  // const filename = file.name; // Get filename for API
  //   console.log("Inside api call rewponse=" + JSON.stringify(base64String));
  //   // Prepare data for API (adjust based on API requirements)
  //   const data1 = {
  //     "file": data, // Base64 encoded file content
  //   //  filename: filename, // Optional: Send filename for storage on server
  //   };

  //   // Send POST request with the base64 data
  //   axios.post("https://server.gaze.co.in/image-to-text", data1)
  //     .then(response => {
  //       console.log("Inside api call rewponse=datatataa" + JSON.stringify(response));
  //     //  resolve(response.data); // Handle successful upload
  //     })
  //     .catch(error => {
  //     //  reject(error); // Handle upload errors
  //     });
  // };

  const response= await apiUploadFile(data);

       
//   const formData = new FormData();
// formData.append("file", data); // Replace 'data' with your image file object

// try{


// const response = await axios.post(
//   "https://server.gaze.co.in/image-to-text",
//  // formData,
  
//   {
    
//     headers: {
//       "Content-Type": "multipart/form-data"
//     }
//   }
// );

// console.log("Response from image ==="+JSON.stringify(response))
// return response.json

// }catch(error){
//   console.log("Response from image ===error"+JSON.stringify(error))
// }
 // setVideos(response.data.items);
 // dispatch(setVideos({"data":data,"videoYoutube":response.data.items}));




// const response = await apiGetYoutubeVideos(data)

return response.json;

})

export const fetchYoutubeTranscripCourse = createAsyncThunk('liveProject/fetchYoutubeTranscripCourse',  async (data, { dispatch })=> {
 

  //const videoId= data;


  const input = {"video_id":data}

      console.log("Inside api call==========="+JSON.stringify(data));
  


  const response = await apiGetYoutubeTranscript(input);
   //console.log("Inside api call==========="+JSON.stringify(response.data));


   const newMessage = {
    question: 'I want to learn the subject passed in transcript passed on context please give the list of topics and subtopics i need to cover ,give the output in this json format, {"studyList":[{topic:topic1,subtopics:[subtopic1,subtopic2]},{topic:topic2,subtopics:[subtopic1,subtopic2]',
  //  last_context:"",
    last_context:JSON.stringify(response.data),
    gpt_model:appConfig.gpt_model
};  


dispatch(getCourseDetailsApiData(newMessage));


  

return response.data
 

})

export const generateQuestionFromTopic = createAsyncThunk('liveProject/generateQuestionFromTopic', async (data) => {
  // console.log("Inside api call"+data);

  const response = await apiGetQuestionsFromPdf(data)
  console.log("Inside api call" + JSON.stringify(response));

  return response.data.answer
})


export const fetchYoutbeVideosTranscript = createAsyncThunk('liveProject/fetchYoutbeVideosTranscript',  async (data, { dispatch })=> {
 

  //const videoId= data;



  const input = {"video_id":data}
  


  const response = await apiGetYoutubeTranscript(input);
   //console.log("Inside api call==========="+JSON.stringify(response.data));

  


   const format = `Generate the result with the following formatting where any heading start with five hash: '##### '.and subheading start with two asterisks: '**'`;
   let respLanguage = ' Give response in language '+store.getState().liveCodeSlice.language;

   const value=JSON.stringify(response.data);
 

       if (value !== "") {
         const newMessage = {
           id: Date.now,
           prompt:format+respLanguage+'Explain in detail with topics and subtopics of the transcript passed in context .Also give 5 most related and relevant question at the end. ',
           client_id:store.getState().auth.session.token.uid,
         //  last_context:(getCompactJavaScriptString(data))+getCompactJavaScriptString(tutorContext?():("")),
         //  last_context:getCompactJavaScriptString(tutorContext?(tutorContext.join(',')):("")),
         //  last_context:'Help me in learning topics and subtopics,'+JSON.stringify(topicSubtopic)+truncateString(trucatedContextPassed,2000),
           last_context:JSON.stringify(response.data),
         //  (convertBlogToWords(courseTopics?(convertBlogToWords(JSON.stringify(courseTopics))):(""))),
           isMe: true,
         };

       dispatch(setLoadingMessagingId(newMessage.id))
       //  setChatMessages([...chatMessages, newMessage]);
        // fetchChatRequest();
      //  console.log("before calling"+message);
      //  sendPromptChatGPT(message);
     //    dispatch(setMessageAddress("tutor"));
       // dispatch(sendMessageToChatLandingBox(value));

       dispatch(setVideoDemanded(false))
      //  dispatch(sendMessageToChatLandingBox(desc))

    //   dispatch(fetchYoutbeVideosApi(value));
        dispatch(getChatGPTApiData(newMessage))
   
       const updatedHistory = [...store.getState().liveCodeSlice.history, value]
      dispatch(updateHistoryLandinPageTutor(updatedHistory))





















 return response.data


// const response = await apiGetYoutubeVideos(data)

// console.log("Inside api call rewponse=" + JSON.stringify(response.data.items));
        }
})








export const getChatGPTApiData = createAsyncThunk('liveProject/getChatGPTApiData', async (data) => {
//  console.log("Inside api call========22222" + JSON.stringify(data));

  const response = await apiAskUniverse(data)


  return response.data
})

export const getCourseDetailsApiData = createAsyncThunk('liveProject/getCourseDetailsApiData', async (data) => {
 // console.log("Inside api call" + data);

  const response = await apiAskCourseDetails(data)


  return response.data
})

export const getCourseTopicsFromTest = createAsyncThunk('liveProject/getCourseTopicsFromTest', async (data) => {
  // console.log("Inside api call" + data);
 
   const response = await apiAskCourseDetails(data)
 
 
   return response.data
 })

export const getSprintStories = createAsyncThunk('liveProject/getSprintStories', async (data) => {
  console.log("Inside api call" + data);

  const response = await apiAskSprintStories(data)


  return response.data
})





const liveCodeSlice = createSlice({
  name: 'liveCodeSlice/state',
  initialState: {
    sideBarExpand: true,
    mobileSideBarExpand: false,
    selectedCategory: {},
    sharedByMe:[],
    reply: false,
    type:'',
    isLoadingSpeech:false,
    personalData:{},
    role:'',
    name:'',
    
    testName:'',
    courseName:'',
    courseStep:'',
    newMessageDialog: false,
    scoreTopic:'',
    messages: [],
    history: [],
    newHistory: [],
    isMe: false,
    takeTest: false,
    newCardAddedIndex: 0,
    selectedQuestions:[],
    
    //studentList:[],
    extractedData: [],
    updatedHistory: "",
    userStories: [],
    isLoadingCourse: false,
    isLoadingCourseOverview:false,
    projectList: [],
    createdCourses: [],
    sharedCourses:[],
    previousNewHistory: [],
    newHistory: [],
    loadingMessageId: 0,
    isLoading: false,
    courseId: 0,
    tutorContext: [],
    errorMessage: '',
    assessmentQuestion: [],
    loadTakeTest: false,
    prevAssessmentQuestion: [],
    loadingUserStories: false,
    sprintsStories: [],
    videoId: '',
    videos: [],
    relatedPrevQuestion:[],
    documentId: '',
    showVideo: false,
    videoToDisplay: '',
    courseUniqueId: '',
    language: 'Hindi',
    showScore:false,
    isVideo:false,
    showConfirm:false,
    sharedId:'',
    sharedUrl:'',
    userList:[],
    mcqString:'',
    messageIdLoaded:'',
    questions:[],
    marketProjectList:[],
    messageRowValue:[],
   
    transcript:[],
    isHidden:true,
   
    isFromRelatedQuestion:false,
    topic:'',
    yourArray:[],
  //  testPercentAge:[],
    testPercentAge:[ { label: 'Novice', value: 30 },
    { label: 'Intermediate', value: 40 },
    { label: 'Proficient', value: 20 },
    { label: 'Expert', value: 10 },
    { label: 'Master', value: 0 }],
    hideEvalute:false,
    clearQuestion:false,
    hideTutor:false,
    query: {
      order: 'asc',
      search: '',
    },
    editorCode: {
      filename: '',
      content: '',
    },

  },
  reducers: {
    updateReply: (state, action) => {
      state.reply = action.payload
    },
    clearTutorContext: (state, action) => {
      state.tutorContext = [];
    },
    setCourseName: (state, action) => {
      state.courseName = action.payload
    },
    setContenType: (state, action) => {
      state.type = action.payload
    },
    setStudentsScores: (state, action) => {
      state.sprintsStories = action.payload
    },
    addItem: (state, action) => {
      // state.list = [...state.list, action.payload]

    //  console.log("studelisttt=="+JSON.stringify(action.payload));
    if(state.marketProjectList && state.marketProjectList.length>0){
      state.marketProjectList=[...state.marketProjectList,action.payload];
    }else{
      state.marketProjectList=[];
      state.marketProjectList=[action.payload];
    }
     
    //  console.log("studelisttt=="+JSON.stringify(state.studentList));
  },
    hideEvaluteFlag: (state, action) => {

      if(action.payload.flag){
        state.hideEvalute=action.payload.flag;

        // let stringArray=[];
   
         if (action.payload.checked) {
           // If boolean is true, add the string if it doesn't exist
           if (!state.yourArray.includes(action.payload.item)) {
             state.yourArray=[...state.yourArray,action.payload.item];
           
           //  setFinalAnseerLocked(stringArray)
            // setAnswerGiven(item)
           //  dispatch(setOptedAnswer(stringArray));
            // setSwitchChangeAsseses(true);
           }
         } else {
           // If boolean is false, remove the string if it exists
           const indexToRemove = state.yourArray.indexOf(action.payload.item);
           if (indexToRemove !== -1) {
             state.yourArray.splice(indexToRemove, 1);
            
           //  setFinalAnseerLocked(stringArray)
          //   dispatch(setOptedAnswer(stringArray));
           //  setSwitchChangeAsseses(false);
           }
         }

      }else{
        state.hideEvalute=action.payload;
      }
   




     // state.showConfirm= true;
    },

    setPercentTestLevel: (state, action) => {

      console.log("Percent Test-===="+action.payload);
   
      state.testPercentAge[action.payload.indexToUpdate].value = action.payload.newValue;
    
   
     // state.testPercentAge=action.payload;
      
    }, setSharedCourses: (state, action) => {
      state.sharedCourses=[...state.sharedCourses,action.payload];
    //  state.showConfirm= true;
    },
     setOptedAnswer: (state, action) => {
      state.yourArray=[...state.yourArray,action.payload];
    //  state.showConfirm= true;
    }, clearQuestions: (state, action) => {

      state.clearQuestion=action.payload;
      state.yourArray=[];
     // state.questions=[];
      }, 
      clearYourArray: (state, action) => {

       // state.clearQuestion=action.payload;
        state.yourArray=[];
       // state.questions=[];
        },

        setTestType: (state, action) => {

          // state.clearQuestion=action.payload;
           state.testName=action.payload;
          // state.questions=[];
           },
      setHideTutor: (state, action) => {

        state.hideTutor=action.payload;
      //  state.yourArray=[];
       // state.questions=[];
        }, 
    removeOptedAnswer: (state, action) => {
    //  state.yourArray = action.payload
    state.yourArray.splice(action.payload, 1);
    }, 
    setUserStoryUpdate: (state, action) => {

      state.projectList[state.courseId].userStory.studyList=action.payload.studyList;
    },
    
    
    setYoutubeVideoLink: (state, action) => {
      state.videoId = action.payload
    }, setSelectedLanguage: (state, action) => {
      state.language = action.payload
    },usersToDisplay: (state, action) => {
      state.userList = [...state.userList,action.payload]
    },sharedByMe: (state, action) => {
      state.extractedData = [...state.extractedData,action.payload]
    },
    fetchSelefStudyTest: (state, action) => {
      state.extractedData = [...state.extractedData,action.payload]
      const values = {
        id: action.payload.id,
        name: action.payload.sharedObject.testName,
        desc: action.payload.sharedObject.testName,
        questions:action.payload.sharedObject.questions,
        messages:action.payload.sharedObject.messages,
        type:'Test',
      //  status:'Test',
        completedQuestions:[],
        score:'',
        userStory:[]
  
    }
    state.projectList=[...state.projectList,values]
    },
    setShowVideo: (state, action) => {
      state.showVideo = action.payload
    },
    clearNewHistory: (state, action) => {
      state.history=[];
      state.newHistory = [];
    },
    clearMessages: (state, action) => {
      state.messages = [];

  
    },
    setPlanData: (state, action) => {
      state.projectList[state.courseId].plan = action.payload;

  
    },
    mergeAllSubRows: (state, action) => {



          //   console.log("Inside sendMessageToChatLandingBox" + action.payload)
          const responseMessage = {
            id: generateRandomId(),
            message:'##### '+ action.payload,
            isMe: true,
            subRows:[]
          };
    
        //  state.tutorContext=[...state.tutorContext,action.payload];
      //  state.questions=[];
    
          state.projectList[state.courseId].questions = [...state.projectList[state.courseId].questions, responseMessage];
          state.isLoading = true;
          state.loadingMessageId = responseMessage.id;
        //  state.videoId = action.payload;
          state.isFromRelatedQuestion=false;


    //  var row=action.payload;
    //  state.messageId=row.original.id;
    //  const updatedData = state.questions.filter((item) => item.id === row.original.id);

    //   console.log("in side remode reducers====updatedData" + JSON.stringify(updatedData[0]))

   //   state.questions=mergeMCQs(updatedData)

 //  const allQuestions= state.questions;

     // state.messageRowValue=mergeMCQs(state.questions);
      state.messageRowValue=mergeMCQs(state.selectedQuestions);

        // Check if the data contains the questions array
  if (state.projectList[state.courseId].questions && Array.isArray(state.projectList[state.courseId].questions)) {
    // Merge the sample mcqList with the existing questions array
  //  state.questions[0].subRows[0].message.mcqList = state.messageRowValue;



//   state.videos=[];

state.projectList[state.courseId].questions.forEach((question, index) => {
  //  console.log("Index involved === " + JSON.stringify(question), row.original.id);
  
   
     // question.subRows[0].message.mcqList = state.messageRowValue;

     if (question.id === state.loadingMessageId) {
      if(question.subRows && question.subRows.length>0){
        question.subRows.push({ id: question.id, message:{"mcqList":state.messageRowValue}, isMe: false, videos: []});
      }else{
        question.subRows =[{ id: question.id, message:{"mcqList":state.messageRowValue}, isMe: false, videos: []}];
      }

    }

    //  question.subRows[1].message.mcqList =[];

      // question.subRows.forEach((question, index) => {
      //   if(index !=0){
      //     question.message.mcqList=[];
      //   }
      

      // });
   // mergeMCQs(question.subRows)
     
 //  question.subRows[0].message.mcqList = [...question.subRows[0].message.mcqList,...mergeMCQs(question.subRows)]
    //  question.subRows[0].message.mcqList = state.messageRowValue;
   
  });

    // state.questions.forEach(index => {

    //   console.log("Index invoved==="+JSON.stringify(index),row.original.id);
    //   if(index.subRows[0].id === row.original.id){
       
    //     index.subRows[0].message.mcqList = state.messageRowValue;

    //    // index.subRows[1]=[]
    //   }
       
    // });
  } else {
    console.error('Invalid data format. Questions array not found.');
  }

    //   console.log("in side remode reducers====updatedData" + JSON.stringify(updatedData))
    //   updatedData.forEach(index => {
    //     state.questions[index].subRows = mergeSubRowsChild(state.questions[index].subRows);

    //     mergedSubRows.push(...subRow);
    // });
    },
    mergeSubRows: (state, action) => {


      var row=action.payload;
      state.messageId=row.original.id;
      const updatedData = state.questions.filter((item) => item.id === row.original.id);

    //   console.log("in side remode reducers====updatedData" + JSON.stringify(updatedData[0]))

   //   state.questions=mergeMCQs(updatedData)

      state.messageRowValue=mergeMCQs(updatedData);

        // Check if the data contains the questions array
  if (state.questions && Array.isArray(state.questions)) {
    // Merge the sample mcqList with the existing questions array
  //  state.questions[0].subRows[0].message.mcqList = state.messageRowValue;

  state.questions.forEach((question, index) => {
    console.log("Index involved === " + JSON.stringify(question), row.original.id);
    if (question.subRows[0].id === row.original.id) {
   
      question.subRows[0].message.mcqList = state.messageRowValue;
    //  question.subRows[1].message.mcqList =[];

      question.subRows.forEach((question, index) => {
        if(index !=0){
          question.message.mcqList=[];
        }
      

      });
   // mergeMCQs(question.subRows)
     
 //  question.subRows[0].message.mcqList = [...question.subRows[0].message.mcqList,...mergeMCQs(question.subRows)]
    //  question.subRows[0].message.mcqList = state.messageRowValue;
    } else {
      // If the row ID doesn't match, remove all other rows from subRows
     // question.subRows = [question.subRows[0]];
    }
  });

    // state.questions.forEach(index => {

    //   console.log("Index invoved==="+JSON.stringify(index),row.original.id);
    //   if(index.subRows[0].id === row.original.id){
       
    //     index.subRows[0].message.mcqList = state.messageRowValue;

    //    // index.subRows[1]=[]
    //   }
       
    // });
  } else {
    console.error('Invalid data format. Questions array not found.');
  }

    //   console.log("in side remode reducers====updatedData" + JSON.stringify(updatedData))
    //   updatedData.forEach(index => {
    //     state.questions[index].subRows = mergeSubRowsChild(state.questions[index].subRows);

    //     mergedSubRows.push(...subRow);
    // });
    },

    updateMCQQuestions: (state, action) => {

    //  state.questions[0].subRows[0].message.mcqList = action.payload;

      if (state.questions && Array.isArray(state.questions)) {
        // Merge the sample mcqList with the existing questions array
      //  state.questions[0].subRows[0].message.mcqList = state.messageRowValue;
    
      state.questions.forEach((question, index) => {
      //  console.log("Index involved === " + JSON.stringify(question), row.original.id);
        if (question.subRows[0].id === state.messageId) {
       
          question.subRows[0].message.mcqList =  action.payload;
        //  question.subRows[1].message.mcqList =[];
    
          question.subRows.forEach((question, index) => {
            if(index !=0){
              question.message.mcqList=[];
            }
          
    
          });
       // mergeMCQs(question.subRows)
         
     //  question.subRows[0].message.mcqList = [...question.subRows[0].message.mcqList,...mergeMCQs(question.subRows)]
        //  question.subRows[0].message.mcqList = state.messageRowValue;
        } else {
          // If the row ID doesn't match, remove all other rows from subRows
         // question.subRows = [question.subRows[0]];
        }
      });
    
        // state.questions.forEach(index => {
    
        //   console.log("Index invoved==="+JSON.stringify(index),row.original.id);
        //   if(index.subRows[0].id === row.original.id){
           
        //     index.subRows[0].message.mcqList = state.messageRowValue;
    
        //    // index.subRows[1]=[]
        //   }
           
        // });
      } else {
        console.error('Invalid data format. Questions array not found.');
      }
    },
    editMessageRow: (state, action) => {
     


      state.projectList[state.courseId].messages.forEach((obj) => {
          if(obj.subRows && obj.subRows.length>0){
    
            const mcqIndex = obj.subRows.findIndex(subRow => subRow.id === action.payload.rowId);
    
            if(mcqIndex !== -1){
    
              obj.subRows[mcqIndex].message = action.payload.content;
            //  state.messageRowValue=row.original.message;
    
            }
            }
     
    });



    },
      removeRow: (state, action) => {
     
        state.projectList[state.courseId].messages = state.projectList[state.courseId].messages.filter((obj) => {
          if (obj.subRows && obj.subRows.length > 0) {
            // Filter out the sub-row with the specified courseUniqueId
            obj.subRows = obj.subRows.filter(subRow => subRow.id !== action.payload);
          }
          // If the message has no sub-rows, remove the entire message
          return obj.subRows && obj.subRows.length > 0;
        });

    //   state.projectList[state.courseId].messages.forEach((obj) => {
    //       if(obj.subRows && obj.subRows.length>0){

    //        // obj.subRows.filter(subRow => subRow.id === state.courseUniqueId);
    //         obj.subRows = obj.subRows.filter(subRow => subRow.id !== state.courseUniqueId);
    
    //         // obj.subRows = obj.subRows.filter(subRow => subRow.id !== state.courseUniqueId);
    //         // const mcqIndex = obj.subRows.findIndex(subRow => subRow.id === state.courseUniqueId);
    
    //         // if(mcqIndex !== -1){
    
    //         //   obj.subRows[mcqIndex].message = action.payload;
    //         // //  state.messageRowValue=row.original.message;
    
    //         // }


    //         }
     
    // });



    },
    editQuestionsRow: (state, action) => {
     


      var row = action.payload;
      console.log("in side remode reducers====" + JSON.stringify(row.original))
      //  state.messages = [];
      const updatedData = state.questions.filter((item) => item.id === row.original.id);
      state.messageRowValue=[];
     state.messageRowValue = [...state.messageRowValue, ...updatedData];





     //var row = action.payload;
     //console.log("in side remode reducers====" + JSON.stringify(row.original.id))
     //  state.messages = [];
     const updatedDataTemp = state.questions.filter((item) => item.id !== row.original.id);
     state.questions = [];
     state.questions = [...state.questions, ...updatedDataTemp];

     




    },
    shareIndividualQuestion: (state, action) => {
     


      var row = action.payload;
   
 
     const updatedDataTemp = state.questions.filter((item) => item.id === row.original.id);

     console.log("in side remode reducers====" + JSON.stringify(updatedDataTemp))
     state.questions = updatedDataTemp;
   //  state.questions = [...state.questions, ...updatedDataTemp];




    },
    setRowBack: (state, action) => {
   
       var updatedDataTemp = action.payload;

       state.projectList[state.courseId].messages.forEach((obj) => {
        console.log("Exsting Index===12"+obj.id,updatedDataTemp[0][0].id)

        if (obj.id === true) {
       //   console.log("Exsting Index==="+obj.id,updatedDataTemp[0].updatedSubRows[0].id)

       //   obj.subRows=updatedDataTemp[0].updatedSubRows[0]
            //  const existingIndex = obj.subRows.findIndex(row => row.id ===action.payload.subRows[0].id);
           
            //   if(existingIndex !== -1){
            //     console.log("Exsting Index==="+existingIndex)
            //   obj.subRows[existingIndex]={ id: action.payload.subRows[0].id, message:action.payload.subRows[0].message, isMe: false, videos:action.payload.subRows[0].videos,file:action.payload.subRows[0].file,
            //     videoLink:action.payload.subRows[0].videoLink }
            //  }

          // obj.subRows.forEach((subR) => {

          //   if(subR.id===action.payload.subRows[0].id){

          //     obj.subRows = [...obj.subRows,{ id: action.payload.subRows[0].id, message:action.payload.subRows[0].message, isMe: false, videos:action.payload.subRows[0].videos,file:action.payload.subRows[0].file,
          //       videoLink:action.payload.subRows[0].videoLink }];

          //   }

          // })


     
           }

  
    });

    //   messageRowValue1[0].subRows[0].message=updatedDataTemp;
    
  //   const updatedDataTemp = state.messages.filter((item) => item.id !== row.original.id);
  //   state.messages = [];
//   state.projectList[state.courseId].messages.forEach((obj) => {

//     if (obj.id === updatedDataTemp.id) {
//       //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
//      //  obj.subRows = [{ id: obj.id, message:action.payload.data.original.message, isMe: false, videos:action.payload.videoYoutube,file:obj.subRows[0].file,
//      //   videoLink:obj.subRows[0].videoLink }];

  

 
//          obj.subRows = [...obj.subRows,{ id: action.payload.subRows[0].id, message:action.payload.subRows[0].message, isMe: false, videos:action.payload.subRows[0].videos,file:action.payload.subRows[0].file,
//            videoLink:action.payload.subRows[0].videoLink }];
       
 
//        }
 

// //   state.videos=[];
// });





    },
    setAssessmentRowBack: (state, action) => {
   
      var updatedDataTemp = action.payload;

      console.log("Data to share====="+JSON.stringify(action.payload));
      const data={ "userStories": updatedDataTemp, "messages": state.messages};

    state.questions = [...state.questions, updatedDataTemp];

   getSharedData(data)
      
    //  dispatch(getSharedData(data));

  



   },
    setSharedId: (state, action) => {
      state.sharedId = action.payload
    },
    setSharedUrl: (state, action) => {
      state.sharedUrl = action.payload
    },
    setVideos: (state, action) => {
    //  state.videos = action.payload.videoYoutube



    state.projectList[state.courseId].messages.forEach((obj) => {


      // const existingIndex = obj.subRows.findIndex(row => row.id === action.payload.data.original.id);

      // if (existingIndex !== -1) {
      //   // Update existing row
      //   obj.subRows[existingIndex] = {
      //     id: obj.id,
      //     message: '',
      //     isMe: false,
      //     videos: action.payload.videoYoutube,
      //     file: obj.subRows[0].file, // Assuming file is consistent for all rows
      //     videoLink: obj.subRows[0].videoLink // Assuming videoLink is consistent for all rows
      //   };
      // } else {
      //   // Add new row
      //   obj.subRows.push({
      //     id: obj.id,
      //     message: '',
      //     isMe: false,
      //     videos: action.payload.videoYoutube,
      //     file: obj.subRows[0].file, // Assuming file is consistent for all rows
      //     videoLink: obj.subRows[0].videoLink // Assuming videoLink is consistent for all rows
      //   });
      // }


      if(obj.subRows && obj.subRows.length>0){

        obj.subRows.forEach((subObj) => {

          if(subObj.id === action.payload.data.original.id){
          
          //  obj.subRows =[{ id: newId, message:state.assessmentQuestion, isMe: false, videos: []}];
              //  obj.subRows.push({ id: Date.now(), message:state.assessmentQuestion, isMe: false, videos: []});
                obj.subRows = [...obj.subRows,{ id: obj.id, message:'', isMe: false, videos:action.payload.videoYoutube,file:obj.subRows[0].file,
                  videoLink:obj.subRows[0].videoLink }];
          }

        });
        
        }else{
           //  obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
             obj.subRows = [{ id: obj.id, message:'', isMe: false, videos:action.payload.videoYoutube,file:obj.subRows[0].file,
              videoLink:obj.subRows[0].videoLink }];
             return;
        }









        
    //   if (obj.id === action.payload.data.original.id) {
    //  //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
    // //  obj.subRows = [{ id: obj.id, message:action.payload.data.original.message, isMe: false, videos:action.payload.videoYoutube,file:obj.subRows[0].file,
    // //   videoLink:obj.subRows[0].videoLink }];

    //     obj.subRows = [...obj.subRows,{ id: obj.id, message:'', isMe: false, videos:action.payload.videoYoutube,file:obj.subRows[0].file,
    //       videoLink:obj.subRows[0].videoLink }];
      

    //   }

   //   state.videos=[];
    });


    },
    setAudio: (state, action) => {
      //  state.videos = action.payload.videoYoutube
  
  
  
      state.projectList[state.courseId].messages.forEach((obj) => {
  
  
          
        if (obj.id === action.payload.data.original.id) {
       //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
             
  
          obj.subRows = [{ id: obj.id, message:action.payload.data.original.message, isMe: false, audioClip:action.payload.audioClip,file:obj.subRows[0].file,
            videoLink:obj.subRows[0].videoLink }];
        
  
        }
  
     //   state.videos=[];
      });
  
  
      },
  
    setDocumentId: (state, action) => {
      state.documentId = action.payload
    },setOverviewState : (state, action) => {
      state.isLoadingCourseOverview = action.payload
    },
    updateProjectIndex: (state, action) => {
          
      state.courseId =action.payload.projectIndex
 
  },
    setCompletedQuestions : (state, action) => {






    //   state.projectList[state.courseId].messages.forEach((obj) => {
  
        
    //     // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
    //  //  if (obj.id === state.loadingMessageId) {
    //        // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
    //        if(obj.subRows && obj.subRows.length>0){
 
    //        obj.subRows.forEach((subObj) => {

             
    //          if(subObj.id === state.loadingMessageId){


    //          const mcqIndex = subObj.message.mcqList.findIndex(subRow => subRow.questionText === state.scoreTopic);

    //        //  console.log("mcqIndex==="+mcqIndex)
    //          if (mcqIndex !== -1) {
    //            // Update the question if found
    //            subObj.message.mcqList[mcqIndex].answerOptions.push({"answerText":action.payload,"questionType":"","isCorrect":true}); 

    //            console.log("mcqIndex==="+JSON.stringify(subObj.message.mcqList[mcqIndex].answerOptions))
    //          } else {
    //            console.error(`Question with text  not found`);
    //          }

    //          }
 
 
    //          // if(subObj.id === state.loadingMessageId){
             
    //          // //  obj.subRows =[{ id: newId, message:state.assessmentQuestion, isMe: false, videos: []}];
    //          //       obj.subRows.push({ id: Date.now(), message:action.payload, isMe: false, videos: []});
    //          //       // subObj.message.mcqList.forEach((mcq) => {
    //          //       //   mcq.answerOptions[0].answerText = action.payload;
    //          //       // });
    //          // }
 
    //        });
           
    //        }else{
    //           //  obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
    //             return;
    //        }
          
 
    //  //  obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
    // //   }
 
    // //   state.videos=[];
    //  });
 













     // state.prevAssessmentQuestion = [state.prevAssessmentQuestion ,...action.payload.yourArray]
    //  state.prevAssessmentQuestion=[];
    //   state.prevAssessmentQuestion=action.payload.yourArray;
  //  JSON.stringify(("Call is herre==="+action.payload))





  // if(state.projectList[state.courseId].completedQuestions  && state.projectList[state.courseId].completedQuestions.length>0 ){
  //   state.projectList[state.courseId].completedQuestions =[ ...state.projectList[state.courseId].completedQuestions,...state.yourArray] 
  //   state.projectList[state.courseId].score = [...state.projectList[state.courseId].score,(action.payload+'/'+state.yourArray.length)];
  
   
  // }else{
  //   state.projectList[state.courseId].completedQuestions =state.yourArray;
  //   state.projectList[state.courseId].score =[(action.payload+'/'+state.yourArray.length)];
  // }





  state.projectList[state.courseId].messages.forEach((obj) => {

    console.log("Inside messageRecievedLandingPageTutor======="+state.loadingMessageId, obj.id);
   // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
//  if (obj.id === state.loadingMessageId) {
      // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
      if(obj.subRows && obj.subRows.length>0){

        const mcqIndex = obj.subRows.findIndex(subRow => subRow.id === state.loadingMessageId);

        if(mcqIndex !== -1){

          obj.subRows[mcqIndex].completedQuestions = state.yourArray;

        }

  
      
      }else{
           obj.subRows =[{ id: Date.now(), message:'', isMe: false, videos: [],completedQuestions:state.yourArray}];
           return;
      }
 
});
 
 // state.yourArray=[];
    // if(state.projectList){

    //   const index = state.projectList.findIndex((item) => item.id === state.courseUniqueId);

    //   console.log("inside call store==index found"+index)
    //   if(index !== -1){
    //  //   state.projectList = [state.projectList[index].messages,...state.messages];   
    //     state.projectList[index].completedQuestions = state.yourArray;
    //     state.projectList[index].score = action.payload;
    //    // state.projectList[index].messages = [...state.projectList[index].messages, ...state.messages]
           
    //   }else{
    //       //   state.projectList=[...state.projectList,values]
    //         }

    // }

    // return;




    // state.projectList[courseUniqueId]
    //   state.scoreTopic=action.payload.score;
    },
    switchTutor: (state, action) => {
      state.takeTest = action.payload
      state.loadTakeTest = false;
      //    state.prevAssessmentQuestion=[...state.prevAssessmentQuestion,...state.assessmentQuestion];
      //    state.assessmentQuestion=[]
    },
    setShowScore: (state, action) => {
      state.showScore = action.payload
    //  state.loadTakeTest = false;
      //    state.prevAssessmentQuestion=[...state.prevAssessmentQuestion,...state.assessmentQuestion];
      //    state.assessmentQuestion=[]
    },
    
    updateTakeTest: (state, action) => {
      state.takeTest = action.payload
      state.loadTakeTest = true;
      //    state.prevAssessmentQuestion=[...state.prevAssessmentQuestion,...state.assessmentQuestion];
      state.assessmentQuestion = []
    },
    
    deleteStateonSignOut: (state, action) => {
      //  state.createdCourses = [],
      state.createdCourses = []
      state.projectList = []
      state.courseId = ''
      state.extractedData = [];
      state.role = '';
      state.name = '';

      state.language ='';
      state.sharedByMe=[];

      state.marketProjectList=[];
      state.personalData=''
      state.documentId=''
      
      

    },
    
    setOfflineAssessment: (state, action) => {
      state.assessmentQuestion = action.payload;
    },
    setAssesmentFromPdf:(state,action)=>{

      state.showScore=false;
      state.errorMessage='';
     
      let mcqTest = [];
      mcqTest = deepParseJson(action.payload);

      //    console.log("deepParseJson(action.payload)dee==11111" + mcqTest);


      if (typeof mcqTest === 'object' && mcqTest !== null) {
        // Variable is an object
        //    console.log('topicsAndSubtopics is an object.');
        //    console.log("deepParseJson(action.payload)dee==22222" + mcqTest);

        state.assessmentQuestion = mcqTest;
        //     state.takeTest= false;
        state.loadTakeTest = false;
      } else {

        state.errorMessage = 'Sever Error: Please try again later.';
        state.loadTakeTest = false;
        return;
        // Variable is not an object
        //    console.log("deepParseJson(action.payload)dee==33333" + mcqTest);
        // const patternIndex = mcqTest.indexOf("```}", 0);
        // //      console.log("deepParseJson(action.payload)dee==444444" + patternIndex);
        // //  const tempJson= {"studyList":[{"topic":"Introduction to Ruby","subtopics":["What is Ruby?","History of Ruby","Features of Ruby"]},{"topic":"Ruby Basics","subtopics":["Variables","Data Types","Operators","Control Structures"]},{"topic":"Ruby Objects and Classes","subtopics":["Objects","Classes","Inheritance","Modules"]},{"topic":"Ruby Methods","subtopics":["Defining Methods","Parameters","Return Values","Scope"]},{"topic":"Ruby Collections","subtopics":["Arrays","Hashes","Ranges","Iterators"]},{"topic":"Ruby File I/O","subtopics":["Reading Files","Writing Files","File Manipulation"]},{"topic":"Ruby Exceptions","subtopics":["Handling Exceptions","Raising Exceptions","Rescuing Exceptions"]},{"topic":"Ruby Modules","subtopics":["Creating Modules","Including Modules","Mixins"]},{"topic":"Ruby Gems","subtopics":["Installing Gems","Using Gems","Creating Gems"]},{"topic":"Ruby on Rails","subtopics":["Introduction to Rails","Models","Views","Controllers"]}]}
        // // const jsonStringWithBracket = topicsAndSubtopics + '}';
        // //  const newObject =deepParseJson(jsonStringWithBracket);
        // // console.log('topicsAndSubtopics is not an object.'+newObject);


        // if (patternIndex !== -1) {
        //   // Extract the JSON string up to the pattern index:
        //   //      console.log("deepParseJson(action.payload)dee==555555" + mcqTest);
        //   const jsonString = mcqTest.substring(0, patternIndex + 4);

        //   try {
        //     const parsedObject = deepParseJson(jsonString);
        //     //       console.log("deepParseJson(action.payload)dee==66666" + mcqTest);
        //     //    console.log(parsedObject);
        //     //  console.log('topicsAndSubtopics is an object.'+parsedObject);

        //     state.assessmentQuestion = parsedObject;
        //     //       console.log("deepParseJson(action.payload)dee==777777" + mcqTest);
        //     state.loadTakeTest = false;
        //   } catch (error) {
        //     state.isLoadingCourse = false;
        //     //    console.log("deepParseJson(action.payload)dee==888888" + mcqTest);

        //     console.error('Error parsing JSON:', error);
        //   }
        // } else {
        //   //       console.log("deepParseJson(action.payload)dee==9999999" + mcqTest);
        //   const jsonStringWithBracket = mcqTest + '}';
        //   const parsedObject = deepParseJson(jsonStringWithBracket);
        //   state.assessmentQuestion = parsedObject;
        //   state.loadTakeTest = false;
        //   //       console.log("deepParseJson(action.payload)dee==101010101" + mcqTest);
        // }


      }


      // state.messages.forEach((obj) => {

      //   //   console.log("Inside messageRecievedLandingPageTutor======="+action.payload.data.original.id);
      //    if (obj.id === action.payload.messagingId) {
      //   //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
              
      //   if(obj.subRowsRelated && obj.subRowsRelated.length>0){
      //     obj.subRows.push(obj.subRowsRelated[0]);
          
      //    // obj.subRowsRelated=[];
      //   }

       
      //    }else{

      //     obj.subRowsRelated=[];
      //    }
   
      // //   state.videos=[];
      //  });

      state.projectList[state.courseId].messages.forEach((obj) => {

        console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
       // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
      if (obj.id === state.loadingMessageId) {
          // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
          if(obj.subRows && obj.subRows.length>0){
          
          }else{
               obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
          }
         

      obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
      }

   //   state.videos=[];
    });


  //     state.projectList[state.courseId].questions.forEach((obj) => {

  //     //  console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
  //      // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
  //     if (obj.id === state.loadingMessageId) {
  //         // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
  //         if(obj.subRows && obj.subRows.length>0){
  //           obj.subRows.push({ id: obj.id, message:action.payload, isMe: false, videos: []});
  //         }else{
  //              obj.subRows =[{ id: obj.id, message:action.payload, isMe: false, videos: []}];
  //         }
         

    
  //     }

  //  //   state.videos=[];
  //   });

  //   state.projectList[state.courseId].questions = [];
  //   state.projectList[state.courseId].questions = [...state.projectList[state.courseId].questions, ...state.questions]



    },
    setSelectedQuestions: (state, action) => {
      const row = action.payload.data;
      const check = action.payload.check;
     
      const index = state.selectedQuestions.findIndex((item) => item.id === row.id);

   //   console.log("inside call store==index found"+index)
      if(index !== -1){
        if(!check){
          state.selectedQuestions.splice(index);
        }
     //   state.projectList = [state.projectList[index].messages,...state.messages];   
        // state.projectList[index].completedQuestions = state.yourArray;
        // state.projectList[index].score = action.payload;
       // state.projectList[index].messages = [...state.projectList[index].messages, ...state.messages]
           
      }else{
        state.selectedQuestions = [...state.selectedQuestions,action.payload.data];
          //   state.projectList=[...state.projectList,values]
            }

    },
  
    setSearchCourseList: (state, action) => {
      state.query.search = action.payload
    },
    setCourseStep: (state, action) => {
      state.courseStep = action.payload
      state.projectList[state.courseId].progress=action.payload.currentIndex;
      state.projectList[state.courseId].totalLength=action.payload.totalLength;
    },
    setProjectDataFromLocal: (state, action) => {

      const projectData= action.payload;

   //   JSON.stringify("The data set==="+JSON.stringify(projectData))
      state.testName = projectData.name?projectData.name:'';
      state.questions=projectData.questions?projectData.questions:[];
      state.messages=projectData.messages?projectData.messages:[];
      state.type=projectData.type?projectData.type:'';
      state.userStories=projectData.userStory?projectData.userStory:[];
    },
    setShareToMeAssessment: (state, action) => {

      const projectData= action.payload;

      JSON.stringify("The data set==="+JSON.stringify(projectData))
      state.testName = projectData.name?projectData.name:'';
      state.questions=projectData.sharedObject.questions?projectData.sharedObject.questions:[];
      state.messages=projectData.sharedObject.messages?projectData.sharedObject.messages:[];
      state.type=projectData.sharedObject.type?projectData.sharedObject.type:'';
      state.userStories=projectData.sharedObject.userStory?projectData.sharedObject.userStory:[];
    },
    updateProjectData: (state, action) => {

//console.log("The data set==="+JSON.stringify(state.projectList[state.courseId]))

   state.projectList[state.courseId].completedQuestions=action.payload.completedQuestions
   state.projectList[state.courseId].score=action.payload.score
   state.projectList[state.courseId].userStory=action.payload.userStory
    state.projectList[state.courseId].messages=action.payload.messages
   state.projectList[state.courseId].progress=action.payload.progress
   state.projectList[state.courseId].totalLength=action.payload.totalLength
   

    },
    shareLearning: (state, action) => {


     state.userStories=[];
    state.messages=[];

    //  console.log("action payload in share leanring====="+JSON.stringify(action.payload.messages));

      state.userStories = action.payload.userStories;
      state.messages = action.payload.messages;
      state.type=action.payload.type;
      state.testName=action.payload.testName;
      state.courseName=action.payload.courseName;
      state.sprintsStories=action.payload.testResult;
      state.scoreTopic=action.payload.score;
      state.courseId=action.payload.courseId;

      state.questions = action.payload.userStories;

      state.messageIdLoaded=action.payload.data;
    },
    setTestFromLocal: (state, action) => {

     const {messages, userStories,type,testName,courseName,data,testResult,score }=action.payload;



     state.questions=userStories;
     state.messages=messages;
     state.type=type;
     state.testName=testName;
     state.courseName=courseName;
     state.userStories=userStories;
     
  

      const values = {
        id: action.payload.data,
        name: testName?testName:courseName,
        desc: testName?testName:courseName,
        questions:userStories,
        messages:messages,
        type:type,
        completedQuestions:[],
        score:score,
        userStory:(type==='Course')?userStories:[]
  
    }
    
    //  console.log("The value here=="+JSON.stringify(values));
     

      if(state.projectList && state.projectList.length>0){

         const index = state.projectList.findIndex((item) => item.id === values.id);
         if(index !== -1){

         }else{
          state.projectList=[...state.projectList,values]
          state.courseId=state.projectList.length-1
         }
        
      }else{
        state.projectList=[values]
        state.courseId=state.projectList.length-1
      }
        

      


    //   state.userStories=[];
    //  state.messages=[];
 
    //  //  console.log("action payload in share leanring====="+JSON.stringify(action.payload.messages));
 
    //    state.userStories = action.payload.userStories;
    //    state.messages = action.payload.messages;
    //    state.type=action.payload.type;
    //    state.testName=action.payload.testName;
    //    state.courseName=action.payload.courseName;
    //    state.sprintsStories=action.payload.testResult;
    //    state.scoreTopic=action.payload.score;
 
    //    state.questions = action.payload.userStories;
 
    //    state.messageIdLoaded=action.payload.data;
     },
   
    loadFromFirestore: (state, action) => {

      //    console.log("In action payloadddddddd===="+JSON.stringify(action.payload.createdThreads))




      state.createdCourses = action.payload.createdCourses
      state.projectList = action.payload.projectList
      state.courseId = action.payload.courseId;
      state.extractedData = action.payload.extractedData;
      state.role = action.payload.role;
      state.name = action.payload.name;

      state.language = action.payload.language;
      state.sharedByMe=action.payload.sharedByMe;

      state.marketProjectList=action.payload.marketProjectList;
      state.personalData=deepParseJson(action.payload.personalData)
      


      
    //  state.userStories = (state.createdCourses && state.createdCourses[state.courseId])?state.createdCourses[state.courseId]:[];
    //  state.questions= state.createdCourses[state.courseId].questions;
      //   state.messages=[];
      //   state.history=[];
      //   state.newHistory=[];
   //   state.questions = (state.createdCourses && state.createdCourses[state.courseId]) ? (state.createdCourses[state.courseId].questions) : [];

   //   state.messages = (state.createdCourses && state.createdCourses[state.courseId])  ? (state.createdCourses[state.courseId].messages) : [];



      //   state.assessmentQuestion = action.payload.assessmentQuestion
      //  state.createdThreads = action.payload.createdThreads
    },

    setDataAfterLogin: (state, action) => {

      //    console.log("In action payloadddddddd===="+JSON.stringify(action.payload.createdThreads))




      state.createdCourses = (action.payload.createdCourses && action.payload.createdCourses.length)>0?action.payload.createdCourses:[]
      state.projectList = (action.payload.projectList && action.payload.projectList.length)>0?action.payload.projectList:[]
      state.courseId = (action.payload.courseId && action.payload.courseId)?action.payload.courseId:''
      state.extractedData = (action.payload.extractedData && action.payload.extractedData.length>0)?action.payload.extractedData:[];
      state.role = action.payload.role?action.payload.role:'';
      state.name = action.payload.name?action.payload.name:'';

      state.language = action.payload.language?action.payload.language:'Hindi';
      state.sharedByMe=(action.payload.sharedByMe && action.payload.sharedByMe.length>0)?action.payload.sharedByMe:[];

      state.marketProjectList=(action.payload.marketProjectList && action.payload.marketProjectList.length>0)?action.payload.marketProjectList:[];
      state.personalData=deepParseJson(action.payload.personalData)
      state.documentId=action.payload.documentId
      


      
    //  state.userStories = state.createdCourses?state.createdCourses[state.courseId]:[];
    //  state.questions= state.createdCourses[state.courseId].questions;
      //   state.messages=[];
      //   state.history=[];
      //   state.newHistory=[];
    //  state.questions = (state.createdCourses) ? (state.createdCourses[state.courseId].questions) : [];

    //  state.messages = (state.createdCourses) ? (state.createdCourses[state.courseId].messages) : [];



      //   state.assessmentQuestion = action.payload.assessmentQuestion
      //  state.createdThreads = action.payload.createdThreads
    },
    updateThreadTopicPart: (state, action) => {

      // const topicsAndSubtopics={};





      const topicsAndSubtopics = { studyList: [{ topic: "", subtopics: [] }] };


      state.isLoadingCourse = false;
      state.isStoryCreated = true;
      state.userStories = [];
      state.userStories = topicsAndSubtopics;
      state.projectList[state.courseId].userStory = topicsAndSubtopics
      state.messages = [];
      state.history = [];
      state.newHistory = [];


    },
    updateUserStoryShared: (state, action) => {

      // const topicsAndSubtopics={};





    //  const topicsAndSubtopics = { studyList: [{ topic: "", subtopics: [] }] };


      state.isLoadingCourse = false;
      state.isStoryCreated = true;
      state.userStories = [];
   //   state.userStories = topicsAndSubtopics;
      state.createdCourses = [...state.createdCourses, state.userStories]
      state.messages = [];
      state.history = [];
      state.newHistory = [];


    },
    updateCourseId: (state, action) => {
      state.courseId = action.payload
    },
    updateCourseUniqueId: (state, action) => {
      state.courseUniqueId = action.payload

    },
    runCodeDynamically: (state, action) => {
      state.editorCode.filename = action.payload.filename
      state.editorCode.content = action.payload.content
    }, setVideoToDisplay: (state, action) => {
      state.videoToDisplay = action.payload

    },resetQuestions: (state, action) => {
      state.questions = [];

    },
    hideTutor: (state, action) => {
      state.isHidden = action.payload

    },
    setVideoDemanded: (state, action) => {
      state.isVideo = action.payload

    },
    
    setTutoContext: (state, action) => {
    //  state.tutorContext = action.payload
      state.tutorContext=[...state.tutorContext,action.payload];

    

    },

    setAnswerGivenByUser: (state, action) => {
      //  state.tutorContext = action.payload
      //  state.tutorContext=[...state.tutorContext,action.payload];
  
        state.projectList[state.courseId].messages.forEach((obj) => {
  
        
         // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
      //  if (obj.id === state.loadingMessageId) {
            // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
            if(obj.subRows && obj.subRows.length>0){
  
            obj.subRows.forEach((subObj) => {

              
              if(subObj.id === state.loadingMessageId){


              const mcqIndex = subObj.message.mcqList.findIndex(subRow => subRow.questionText === state.scoreTopic);

            //  console.log("mcqIndex==="+mcqIndex)
              if (mcqIndex !== -1) {
                // Update the question if found
                subObj.message.mcqList[mcqIndex].answerOptions.push({"answerText":action.payload,"questionType":"","isCorrect":true}); 

               // console.log("mcqIndex==="+JSON.stringify(subObj.message.mcqList[mcqIndex].answerOptions))
              } else {
                console.error(`Question with text  not found`);
              }

              }
  
  
              // if(subObj.id === state.loadingMessageId){
              
              // //  obj.subRows =[{ id: newId, message:state.assessmentQuestion, isMe: false, videos: []}];
              //       obj.subRows.push({ id: Date.now(), message:action.payload, isMe: false, videos: []});
              //       // subObj.message.mcqList.forEach((mcq) => {
              //       //   mcq.answerOptions[0].answerText = action.payload;
              //       // });
              // }
  
            });
            
            }else{
               //  obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
                 return;
            }
           
  
      //  obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
     //   }
  
     //   state.videos=[];
      });
  
      },
    setLoadingMessagingId: (state, action) => {
      //  state.tutorContext = action.payload
        state.loadingMessageId=action.payload;
  
      },
      setCanvasDataToRow: (state, action) => {
        //  state.tutorContext = action.payload
     //     state.projectList[state.courseId].mess=action.payload;


      state.projectList[state.courseId].messages.forEach((obj) => {


        if (obj.id === state.loadingMessageId) {
       
     
             obj.subRows = [...obj.subRows,{ id: action.payload.id, message:'', isMe: false, canvas:{"drawingActions":action.payload.canvasContext}
                }];
           
     
           }

  
    });


          state.sharedId=action.payload.id;
    
      },

      updateCanvasDataToRow: (state, action) => {
        //  state.tutorContext = action.payload
     //     state.projectList[state.courseId].mess=action.payload;


      state.projectList[state.courseId].messages.forEach((obj) => {


        if (obj.id === state.loadingMessageId) {

          obj.subRows.forEach((subR) => {

            if(subR.id===action.payload.id){

              subR.canvas=action.payload.canvasContext

            }

          })


       
     
            //  obj.subRows = [...obj.subRows,{ id: action.payload.id, message:'', isMe: false, canvas:{"drawingActions":action.payload.canvasContext}
            //     }];
           
     
           }

  
    });


       //   state.loadingMessageId=action.payload.id;
    
      },

      setRelatedQuestions: (state, action) => {
        //  state.tutorContext = action.payload

        if(state.loadingMessageId ===action.payload.messagingId){

          state.relatedPrevQuestion=[...state.relatedPrevQuestion,action.payload.question]
          state.isFromRelatedQuestion=true;
        
        }else{

          state.relatedPrevQuestion=[];
          state.loadingMessageId=action.payload.messagingId;
          state.relatedPrevQuestion=[...state.relatedPrevQuestion,action.payload.question]
          state.isFromRelatedQuestion=true;

        }

        state.isHidden=false;

        state.tutorContext=[...state.tutorContext,action.payload.question.message];



        state.projectList[state.courseId].messages.forEach((obj) => {

          //   console.log("Inside messageRecievedLandingPageTutor======="+action.payload.data.original.id);
           if (obj.id === action.payload.messagingId) {
          //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
                
          if(obj.subRowsRelated && obj.subRowsRelated.length>0){
            obj.subRows.push(obj.subRowsRelated[0]);
            
           // obj.subRowsRelated=[];
          }

         
           }else{

            obj.subRowsRelated=[];
           }
     
        //   state.videos=[];
         });
         

          
         
          
    
        },
       
        setRelatedAssessment: (state, action) => {
          //  state.tutorContext = action.payload
  
          if(state.loadingMessageId ===action.payload.messagingId){
  
            state.relatedPrevQuestion=[...state.relatedPrevQuestion,action.payload.question]
            state.isFromRelatedQuestion=true;
          
          }else{
  
            state.relatedPrevQuestion=[];
            state.loadingMessageId=action.payload.messagingId;
            state.relatedPrevQuestion=[...state.relatedPrevQuestion,action.payload.question]
            state.isFromRelatedQuestion=true;
  
          }
  
       //   state.isHidden=false;
  
        //  state.tutorContext=[...state.tutorContext,action.payload.question.message];
  
  
  
          state.questions.forEach((obj) => {
  
            
            //   console.log("Inside messageRecievedLandingPageTutor======="+action.payload.data.original.id);
             if (obj.id === action.payload.messagingId) {
            //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
                  
            if(obj.subRowsRelated && obj.subRowsRelated.length>0){
              obj.subRows.push(obj.subRowsRelated[0]);
              
             // obj.subRowsRelated=[];
            }
  
            
       
               
             
            //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+obj.subRows);
               //  obj.videos=state.videos;
               //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];
       
               //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
             }else{
  
              obj.subRowsRelated=[];
             }
       
          //   state.videos=[];
           });
           
  
            
           
            
      
          },

      // setRelatedAssessment: (state, action) => {
      //     //  state.tutorContext = action.payload

      //     console.log("Action payloaddd="+JSON.stringify(action.payload));
      //     console.log("Action payloaddd=loading"+JSON.stringify(state.loadingMessageId));
  
      //     if(state.loadingMessageId ===action.payload.messagingId){
  
      //       state.relatedPrevQuestion=[...state.relatedPrevQuestion,action.payload.question]
      //    //   state.isFromRelatedQuestion=true;
          
      //     }else{
  
      //       state.relatedPrevQuestion=[];
      //       state.loadingMessageId=action.payload.messagingId;
      //       state.relatedPrevQuestion=[...state.relatedPrevQuestion,action.payload.question]
      //    //   state.isFromRelatedQuestion=true;
  
      //     }
  
      //   //  state.isHidden=false;
  
      //     state.tutorContext=[...state.tutorContext,action.payload.question.message];
  
  
  
      //     state.questions.forEach((obj) => {
  
      //         console.log("Inside messageRecievedLandingPageTutorDeeoak======="+obj.id,action.payload.messagingId);
      //      //  obj.subRows.push(obj.subRowsRelated[0]);
      //        if (obj.id === action.payload.messagingId) {
      //          console.log("Inside messageRecievedLandingPageTutorDeepak=+++++++"+obj.subRowsRelated);
      //          obj.subRows.push(state.newHistory[0]);
      //       // if(obj.subRowsRelated && obj.subRowsRelated.length>0){
      //       //   obj.subRows.push(obj.question[0]);
              
      //       //  // obj.subRowsRelated=[];
      //       // }
  
            
       
               
             
      //       //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+obj.subRows);
      //          //  obj.videos=state.videos;
      //          //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];
       
      //          //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
      //        }else{
  
      //         obj.subRowsRelated=[];
      //        }
       
      //     //   state.videos=[];
      //      });
           
  
            
           
            
      
      //     },  
    updateHistoryLandinPageTutor(state, action) {
      // state.history =[...state.history,...state.messages]
     // state.tutorContext = [...state.tutorContext, ...state.newHistory]
      state.history = [];
      state.newHistory = [];
      state.previousNewHistory = [];
    },
    updateRemovedRowLandingpagetutor: (state, action) => {

      var row = action.payload;
      console.log("in side remode reducers====" + JSON.stringify(row.original.id))
      //  state.messages = [];
      const updatedData = state.messages.filter((item) => item.id !== row.original.id);
      state.messages = [];
      state.messages = [...state.messages, ...updatedData];

    },
    deleteProject: (state, action) => {

   //   var row = action.payload;
   //   console.log("in side remode reducers====" + JSON.stringify(row.original.id))
      //  state.messages = [];
      state.projectList  = state.projectList.filter((item) => item.id !== action.payload);
      // state.projectList = [];
      // state.projectList = [...state.projectList, ...updatedData];

    },
    setCourseId: (state, action) => {
      //   state.courseId = action.payload

      
if(state.createdCourses && state.createdCourses[state.courseId]){
  state.userStories = state.createdCourses[state.courseId];
  state.messages = [];
  state.history = [];
  state.newHistory = [];
  state.messages = (state.createdCourses[state.courseId].messages) ? (state.createdCourses[state.courseId].messages) : [];
  state.questions = (state.createdCourses[state.courseId].questions) ? (state.createdCourses[state.courseId].questions) : [];

  state.tutorContext = (state.projectList[state.courseId].tutorContext) ? (state.projectList[state.courseId].tutorContext) : [];


}
     

    },
    setQuestionsStudent: (state, action) => {
      //   state.courseId = action.payload
      const values= action.payload;
 
    //  const updatedData = projectList.filter((item) => item.id === receivedData);

   //   state.userStories = state.createdCourses[state.courseId];
      state.messages = values.messages;
      state.history = [];
      state.newHistory = [];
      state.questions=values.questions;
      state.sprintsStories=values.completedQuestions;
      // state.messages = (state.createdCourses[state.courseId].messages) ? (state.createdCourses[state.courseId].messages) : [];
      // state.questions = (state.createdCourses[state.courseId].questions) ? (state.createdCourses[state.courseId].questions) : [];
   
  //    state.tutorContext = (state.projectList[state.courseId].tutorContext) ? (state.projectList[state.courseId].tutorContext) : [];


    },
    fetchQuestionsStudent: (state, action) => {
      //   state.courseId = action.payload
    //  const values= action.payload;
    const values = action.payload;
if(state.projectList){

}else{
  state.projectList=[];
}
 // const index = state.projectList.findIndex((item) => item.id === action.payload);

       const updatedData = state.projectList.filter((item) => item.id === action.payload);

       console.log("Filter data==="+JSON.stringify(updatedData),action.payload)

  if (updatedData[0] && updatedData[0].id===action.payload) {
 
      state.questions=updatedData[0].questions
      state.messages=updatedData[0].messages
      state.type=updatedData[0].type
      state.sprintsStories=updatedData[0].completedQuestions
   //   state.score=updatedData.score,


    
  }else{

//loadShredTestFrmServer(values.sharedData);
  //dispatch(setTestFromLocal({ messages, userStories,type,testName,courseName,data,testResult,score }));
//  setTestFromLocal(action.payload);




//   const values1 = {
//     id: values.sharedData,
//     name: values.contentName,
//     desc: values.contentName,
//     questions:[],
//     messages:[],
//     type:values.status,
//     completedQuestions:[],
//     hostId:values.personalInfo,
//     score:'',

// }

//   console.log("TFilter data== else"+JSON.stringify(values1));
 

//     state.projectList=[...state.projectList,values1]
 



}
   

   
  //     const updatedData = projectList.filter((item) => item.id === action.payload);

  //  //   state.userStories = state.createdCourses[state.courseId];
  //  updatedData.messages = values.messages;
  //  //   state.history = [];
  // //    state.newHistory = [];
  // updatedData.questions=values.questions;
  // updatedData.sprintsStories=values.completedQuestions;
      // state.messages = (state.createdCourses[state.courseId].messages) ? (state.createdCourses[state.courseId].messages) : [];
      // state.questions = (state.createdCourses[state.courseId].questions) ? (state.createdCourses[state.courseId].questions) : [];
   
  //    state.tutorContext = (state.projectList[state.courseId].tutorContext) ? (state.projectList[state.courseId].tutorContext) : [];


    },
    setImportedCourseId: (state, action) => {
      //   state.courseId = action.payload

      

   //   state.userStories = state.createdCourses[state.courseId];
    
    //  state.messages = (state.createdCourses[state.courseId].messages) ? (state.createdCourses[state.courseId].messages) : [];
    //  state.questions = (state.createdCourses[state.courseId].questions) ? (state.createdCourses[state.courseId].questions) : [];
   
  //    state.tutorContext = (state.projectList[state.courseId].tutorContext) ? (state.projectList[state.courseId].tutorContext) : [];


    },
    sendMessageToChatLandingBox: (state, action) => {

   //   console.log("Inside sendMessageToChatLandingBox" + action.payload)
      const responseMessage = {
        id: generateRandomId(),
        message:'##### '+ action.payload,
        isMe: true
      };

      state.tutorContext=[...state.tutorContext,action.payload];

      state.projectList[state.courseId].messages = [...state.projectList[state.courseId].messages, responseMessage];
      state.isLoading = true;
      state.loadingMessageId = responseMessage.id;
      state.videoId = action.payload;
      state.isFromRelatedQuestion=false;
    },
    sendMessageToTestLandingBox: (state, action) => {

      //   console.log("Inside sendMessageToChatLandingBox" + action.payload)
         const responseMessage = {
           id: generateRandomId(),
           message:'##### '+ action.payload,
           isMe: true
         };
   
       //  state.tutorContext=[...state.tutorContext,action.payload];
     //  state.questions=[];
   
         state.questions = [...state.questions, responseMessage];
         state.isLoading = true;
         state.loadingMessageId = responseMessage.id;
       //  state.videoId = action.payload;
         state.isFromRelatedQuestion=false;
       },
    messageRecievedThreadTutor: (state, action) => {


      state.previousNewHistory[0] = state.newHistory[0]
      state.newHistory = []
      //  state.history=[]

      // state.newHistory = []
      state.history = [...state.history, action.payload]
      state.newHistory = [...state.newHistory, state.history.join('')]

      // state.messages.forEach((obj) => {

      // //  console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
      //   if (obj.id === state.loadingMessageId) {

      // //    console.log("Inside messageRecievedLandingPageTutor=+++++++"+state.newHistory[0]);

      //    obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
      // //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];

      //  //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
      //   }
      // });




      let topicsAndSubtopics = [];
      topicsAndSubtopics = deepParseJson(state.newHistory[0]);

      //   console.log("deepParseJson(action.payload)dee==" + topicsAndSubtopics);


      if (typeof topicsAndSubtopics === 'object' && topicsAndSubtopics !== null) {
        // Variable is an object
        console.log('topicsAndSubtopics is an object.');

        state.isLoadingCourse = false;
        state.isStoryCreated = true;
        state.userStories = [];
        state.userStories = topicsAndSubtopics;
        state.createdCourses = [...state.createdCourses, topicsAndSubtopics]
        state.messages = [];
        state.history = [];
        state.newHistory = [];
      } else {
        // Variable is not an object
        const patternIndex = topicsAndSubtopics.indexOf("}]}", 0);
        //  const tempJson= {"studyList":[{"topic":"Introduction to Ruby","subtopics":["What is Ruby?","History of Ruby","Features of Ruby"]},{"topic":"Ruby Basics","subtopics":["Variables","Data Types","Operators","Control Structures"]},{"topic":"Ruby Objects and Classes","subtopics":["Objects","Classes","Inheritance","Modules"]},{"topic":"Ruby Methods","subtopics":["Defining Methods","Parameters","Return Values","Scope"]},{"topic":"Ruby Collections","subtopics":["Arrays","Hashes","Ranges","Iterators"]},{"topic":"Ruby File I/O","subtopics":["Reading Files","Writing Files","File Manipulation"]},{"topic":"Ruby Exceptions","subtopics":["Handling Exceptions","Raising Exceptions","Rescuing Exceptions"]},{"topic":"Ruby Modules","subtopics":["Creating Modules","Including Modules","Mixins"]},{"topic":"Ruby Gems","subtopics":["Installing Gems","Using Gems","Creating Gems"]},{"topic":"Ruby on Rails","subtopics":["Introduction to Rails","Models","Views","Controllers"]}]}
        // const jsonStringWithBracket = topicsAndSubtopics + '}';
        //  const newObject =deepParseJson(jsonStringWithBracket);
        // console.log('topicsAndSubtopics is not an object.'+newObject);


        if (patternIndex !== -1) {
          // Extract the JSON string up to the pattern index:
          const jsonString = topicsAndSubtopics.substring(0, patternIndex + 3);

          try {
            const parsedObject = deepParseJson(jsonString);
            //    console.log(parsedObject);
            //  console.log('topicsAndSubtopics is an object.'+parsedObject);

            state.isLoadingCourse = false;
            state.isStoryCreated = true;
            state.userStories = [];
            state.userStories = parsedObject;
            state.createdCourses = [...state.createdCourses, parsedObject]
            state.messages = [];
            state.history = [];
            state.newHistory = [];
          } catch (error) {
            state.isLoadingCourse = false;

            console.error('Error parsing JSON:', error);
          }
        } else {
          const jsonStringWithBracket = topicsAndSubtopics + '}';
          const parsedObject = deepParseJson(jsonStringWithBracket);
          state.isLoadingCourse = false;
          state.isStoryCreated = true;
          state.userStories = [];
          state.userStories = parsedObject;
          state.createdCourses = [...state.createdCourses, parsedObject]
          state.messages = [];
          state.history = [];
          state.newHistory = [];
          console.error('Pattern not found in JSON string.');
        }








      }







    },messageReceivedMarketplaceTutor: (state, action) => {


      state.previousNewHistory[0] = state.newHistory[0]
      state.newHistory = []
      //  state.history=[]

      // state.newHistory = []
      state.history = [...state.history, action.payload]
      state.newHistory = [...state.newHistory, state.history.join('')]

      const videosToEmbed = (state.isVideo)?(state.videos):("");

      state.messages.forEach((obj) => {

        //  console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
        if (obj.id === state.loadingMessageId) {

          //    console.log("Inside messageRecievedLandingPageTutor=+++++++"+state.newHistory[0]);

          if(state.isFromRelatedQuestion){

        
              
            obj.subRowsRelated=[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];

          }
          else{

            obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
 
          }

        //  obj.subRows = [{ id: obj.id, message: state.newHistory[0], isMe: false, videos: videosToEmbed}];
          //  obj.videos=state.videos;
          //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];

          //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
        }

     //   state.videos=[];
      });

      //  state.projectList = [state.projectList[0].messages,...state.messages];
      if(state.projectList && state.projectList[state.courseId] ){
        state.projectList[state.courseId].messages = [];
        state.projectList[state.courseId].messages = [...state.projectList[state.courseId].messages, ...state.messages]

      }

  
      // if(state.questions[0].message.questionAsked ){
    
      //   state.questions[0].message.questionAsked  = state.messages
      // }else{
      //   state.questions[0].message.questionAsked = [];
      //   state.questions[0].message.questionAsked  = state.messages
      // }
     
     


    //  state.sharedCourses[state.messageIdLoaded].messages = [];
    //  state.sharedCourses[state.messageIdLoaded] = state.messages;

    

    },
     setCurrentQuestionAsked: (state, action) => {

      state.scoreTopic=action.payload;
      //  state.homePageQuestion =[...state.homePageQuestion,{"questionAsked":questionAsked,"questionAnswered":state.newHistory[0]}] ;
      
      },
    messageRecievedStudentCourse: (state, action) => {

      state.previousNewHistory[0] = state.newHistory[0]
    
      state.newHistory = []
      //  state.history=[]

      // state.newHistory = []
      state.history = [...state.history, action.payload]
      state.newHistory = [...state.newHistory, state.history.join('')]



     

    
     // state.homePageQuestion =[...state.homePageQuestion,{"questionAsked":action.payload,"questionAnswered":state.newHistory[0]} ];
    
    },
    messageRecievedLandingPageTutor: (state, action) => {


      state.previousNewHistory[0] = state.newHistory[0]
    
      state.newHistory = []
      //  state.history=[]

      // state.newHistory = []
      state.history = [...state.history, action.payload]
      state.newHistory = [...state.newHistory, state.history.join('')]

      const videosToEmbed = (state.isVideo)?(state.videos):("");

      state.projectList[state.courseId].messages.forEach((obj) => {

        //  console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
        if (obj.id === state.loadingMessageId) {

          //    console.log("Inside messageRecievedLandingPageTutor=+++++++"+state.newHistory[0]);

       

         //   obj.subRows =[{ id: obj.id, message:state.relatedPrevQuestion+'. '+state.newHistory[0], isMe: false, videos: videosToEmbed}];
   
        
            // if(state.isFromRelatedQuestion){

        
              
            // obj.subRowsRelated=[{ id: state.courseUniqueId, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];

            // }
            // else{

            obj.subRows =[{ id: state.courseUniqueId, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
   
        //    }

          //  state.isFromRelatedQuestion=false;
       //  obj.videos=state.videos;
          //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];

          //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
        }else{



          if(obj.subRows && obj.subRows.length>0){

            obj.subRows.forEach((subObj) => {
  
              if(subObj.id === state.loadingMessageId){

                obj.subRows =[...obj.subRows,{ id: state.courseUniqueId, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
   
              
              //  obj.subRows =[{ id: newId, message:state.assessmentQuestion, isMe: false, videos: []}];
                  //  obj.subRows.push({ id: Date.now(), message:state.newHistory[0], isMe: false, videos: []});
              }
  
            });
            
            }else{
                 obj.subRows =[{ id: state.courseUniqueId, message:state.newHistory[0], isMe: false, videos: []}];
                 return;
            }








        }

     
      });


    //  state.courseUniqueId='';

      //  state.createdCourses = [state.createdCourses[0].messages,...state.messages];


   // state.projectList[state.courseId].messages = [];
   //   state.projectList[state.courseId].messages = [...state.projectList[state.courseId].messages, ...state.messages]

      // if(state.tutorContext){
      // state.projectList[state.courseId].tutorContext = state.tutorContext;
      // }else{
      // state.tutorContext=[];
      // state.projectList[state.courseId].tutorContext = state.tutorContext;
      // }



      // if(state.projectList){
      //   state.projectList[state.courseId].messages = [];
      //   state.projectList[state.courseId].messages = [...state.projectList[state.courseId].messages, ...state.messages]

      // }

     
     
      //state.videos=[];

      //   const isObjectUpdated = () => {
      //     const currentObject = state.2298CodeSlice.newHistory[0];
      //     const previousObject = state.liveCodeSlice.previousNewHistory[0]; // Assuming you store the previous object in your state

      //     // Check if both objects exist
      //     if (currentObject && previousObject) {
      //         // Convert the objects to JSON strings for comparison
      //         const currentObjectString = JSON.stringify(currentObject);
      //         const previousObjectString = JSON.stringify(previousObject);

      //         // Compare the JSON strings
      //         if (currentObjectString == previousObjectString) {
      //             // The objects are different
      //             return true;
      //         }
      //         return false;
      //     }

      // };


      // if(  isObjectUpdated()){

      // try{
      //   const documentRef = doc(usersCollection,  state.documentId);
      //    updateDoc(documentRef, state);
      //   console.log('Document successfully updated!');
      // } catch (error) {
      //   console.error('Error updating document:', error);
      // }
      // }




    },

    messageRecievedLearnTestFrmTutor: (state, action) => {


      state.previousNewHistory[0] = state.newHistory[0]
    
      state.newHistory = []
      //  state.history=[]

      // state.newHistory = []
      state.history = [...state.history, action.payload]
      state.newHistory = [...state.newHistory, state.history.join('')]

      const videosToEmbed = (state.isVideo)?(state.videos):("");

      state.messages.forEach((obj) => {

        //  console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
        if (obj.id === state.loadingMessageId) {

          //    console.log("Inside messageRecievedLandingPageTutor=+++++++"+state.newHistory[0]);

       

         //   obj.subRows =[{ id: obj.id, message:state.relatedPrevQuestion+'. '+state.newHistory[0], isMe: false, videos: videosToEmbed}];
   
      
            if(state.isFromRelatedQuestion){

        
              
              obj.subRowsRelated=[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];

            }
            else{

              obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
   
            }

          //  state.isFromRelatedQuestion=false;
       //  obj.videos=state.videos;
          //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];

          //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
        }

     //   state.videos=[];
      });

      //  state.createdCourses = [state.createdCourses[0].messages,...state.messages];


      // state.createdCourses[state.courseId].messages = [];
      // state.createdCourses[state.courseId].messages = [...state.createdCourses[state.courseId].messages, ...state.messages]

      // if(state.tutorContext){
      //   state.projectList[state.courseId].tutorContext = state.tutorContext;
      // }else{
      //   state.tutorContext=[];
      //   state.projectList[state.courseId].tutorContext = state.tutorContext;
      // }



      if(state.projectList){
        state.projectList[state.courseId].messages = [];
        state.projectList[state.courseId].messages = [...state.projectList[state.courseId].messages, ...state.messages]

      }

     
     
      //state.videos=[];

      //   const isObjectUpdated = () => {
      //     const currentObject = state.2298CodeSlice.newHistory[0];
      //     const previousObject = state.liveCodeSlice.previousNewHistory[0]; // Assuming you store the previous object in your state

      //     // Check if both objects exist
      //     if (currentObject && previousObject) {
      //         // Convert the objects to JSON strings for comparison
      //         const currentObjectString = JSON.stringify(currentObject);
      //         const previousObjectString = JSON.stringify(previousObject);

      //         // Compare the JSON strings
      //         if (currentObjectString == previousObjectString) {
      //             // The objects are different
      //             return true;
      //         }
      //         return false;
      //     }

      // };


      // if(  isObjectUpdated()){

      // try{
      //   const documentRef = doc(usersCollection,  state.documentId);
      //    updateDoc(documentRef, state);
      //   console.log('Document successfully updated!');
      // } catch (error) {
      //   console.error('Error updating document:', error);
      // }
      // }






    },
    parsedQuestions: (state, action) => {

      state.questions=deepParseJson(action.payload);


    },
    messageRecievedAssessment: (state, action) => {


      state.previousNewHistory[0] = state.newHistory[0]
    
      state.newHistory = []
      //  state.history=[]

      // state.newHistory = []
      state.history = [...state.history, action.payload]
      state.newHistory = [...state.newHistory, state.history.join('')]

      const videosToEmbed = (state.isVideo)?(state.videos):("");

      state.questions.forEach((obj) => {

          console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
         // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
        if (obj.id === state.loadingMessageId) {

          console.log("Inside messageRecievedLandingPageTutor======messaging id="+state.loadingMessageId);

          //    console.log("Inside messageRecievedLandingPageTutor=+++++++"+state.newHistory[0]);

       

         //   obj.subRows =[{ id: obj.id, message:state.relatedPrevQuestion+'. '+state.newHistory[0], isMe: false, videos: videosToEmbed}];
   
      
            if(!state.isFromRelatedQuestion ){

              // obj.subRowsRelatedSaved=[obj.subRowsRelatedSaved,obj.subRowsRelated];
              // obj.subRowsRelated=[];
              
           //   obj.subRowsRelated=[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];


      
              obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];

              console.log("subrowss========+"+obj.id)

            //  obj.subRowsRelated=[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
   

   
            

            }
            else if (state.isFromRelatedQuestion){

              console.log("subrowss========+11162435645423632642364364"+obj.id)
              obj.subRowsRelated=[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];

            //  obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
   
            }

          //  state.isFromRelatedQuestion=false;
       //  obj.videos=state.videos;
          //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];

          //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
        }

     //   state.videos=[];
      });

     // state.questions = deepParseJson(state.newHistory[0]);
//
   //   state.questions = destate.newHistory[0]);
     

      // const currentObject = state.newHistory[0];
      // const previousObject = state.previousNewHistory[0]; // Assuming you store the previous object in your state
  
      // // Check if both objects exist
      // if (currentObject && previousObject) {
      //   // Convert the objects to JSON strings for comparison
      //   const currentObjectString = JSON.stringify(currentObject);
      //   const previousObjectString = JSON.stringify(previousObject);
  
      //   // Compare the JSON strings
      //   if (currentObjectString == previousObjectString) {

      //     state.mcqString = state.newHistory[0];
      //     // The objects are different
      //  //   return true;
      //   }
      // }










    //  state.mcqString=state.newHistory[0];

    //   const videosToEmbed = (state.isVideo)?(state.videos):("");

    //   state.messages.forEach((obj) => {

    //     //  console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
    //     if (obj.id === state.loadingMessageId) {

    //       //    console.log("Inside messageRecievedLandingPageTutor=+++++++"+state.newHistory[0]);

    //       obj.subRows = [{ id: obj.id, message: state.newHistory[0], isMe: false, videos: videosToEmbed}];
    //       //  obj.videos=state.videos;
    //       //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];

    //       //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
    //     }

    //  //   state.videos=[];
    //   });

      //  state.createdCourses = [state.createdCourses[0].messages,...state.messages];


      state.createdCourses[state.courseId].questions = [];
      state.createdCourses[state.courseId].questions = [...state.createdCourses[state.courseId].questions, ...state.questions]

      //state.videos=[];

      //   const isObjectUpdated = () => {
      //     const currentObject = state.liveCodeSlice.newHistory[0];
      //     const previousObject = state.liveCodeSlice.previousNewHistory[0]; // Assuming you store the previous object in your state

      //     // Check if both objects exist
      //     if (currentObject && previousObject) {
      //         // Convert the objects to JSON strings for comparison
      //         const currentObjectString = JSON.stringify(currentObject);
      //         const previousObjectString = JSON.stringify(previousObject);

      //         // Compare the JSON strings
      //         if (currentObjectString == previousObjectString) {
      //             // The objects are different
      //             return true;
      //         }
      //         return false;
      //     }

      // };


      // if(  isObjectUpdated()){

      // try{
      //   const documentRef = doc(usersCollection,  state.documentId);
      //    updateDoc(documentRef, state);
      //   console.log('Document successfully updated!');
      // } catch (error) {
      //   console.error('Error updating document:', error);
      // }
      // }






    },
    putProject: (state, action) => {

    //  console.log("putProject", action.payload);
      //  state.projectList = projectData.projectList;

    
     

      if(state.projectList){
        state.projectList = [...state.projectList, action.payload]
        state.courseId=state.projectList.length-1
      
      }else{
        state.projectList=[];
        state.projectList=action.payload
      }
     
      state.loading = false
      // state.scrollToTopFlag = true;

    },
    putMarketplaceProject: (state, action) => {

   
      state.marketProjectList = action.payload;
     // state.loading = false

      // state.scrollToTopFlag = true;

    },
    setCourseLodingIcon: (state, action) => {
      state.isLoadingCourse = action.payload;
    },
    setExtractedData: (state, action) => {
      state.extractedData = action.payload;
    },
    setScoreForTopic: (state, action) => {
      state.projectList[state.courseId][state.scoreTopic] =action.payload;
    },
    setQuestionScore: (state, action) => {
      state.mcqString =action.payload;
    },
    setTopicTest: (state, action) => {
      state.scoreTopic=action.payload;
    },
    toggleSidebar: (state, action) => {
      state.sideBarExpand = action.payload
    },
    toggleMobileSidebar: (state, action) => {
      state.mobileSideBarExpand = action.payload
    },
    toggleNewMessageDialog: (state, action) => {
      state.newMessageDialog = action.payload
    },
    updateSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload
    },
    sendMessageToChatBoxProjectTutor: (state, action) => {
      const responseMessage = {
        id: generateRandomId(),
        message: action.payload,
        isMe: true
      };

      state.messages = [...state.messages, responseMessage];
      state.isLoading = true;
      state.loadingMessageId = responseMessage.id;
    },

  },
  extraReducers: (builder) => {
    //  console.log("Inside sendPromptChatGPT"+builder);
    builder
      .addCase(getCourseDetailsApiData.fulfilled, (state, action) => {





        let topicsAndSubtopics = [];
        topicsAndSubtopics = deepParseJson(action.payload);
    
    
        if (typeof topicsAndSubtopics === 'object' && action.payload !== null) {
          // If the payload is already an object, use it directly
          topicsAndSubtopics = action.payload;
          state.projectList[state.courseId].userStory = topicsAndSubtopics;
        //  state.assessmentQuestion=mcqTest;
        state.isLoadingCourse = false;
        state.isStoryCreated = true;
        //  mcqTest = deepParseJson(action.payload);
      //    console.log("Parsed JSON object:", mcqTest);
        } else if (typeof action.payload === 'string' && action.payload.trim() !== '') {
          // If the payload is a non-empty string, try to parse it as JSON
          try {
            const cleanedPayload = action.payload.replace(/```json\n|```/g, '');
            let jsonData = cleanedPayload.match(/{[\s\S]*}/)[0]; // This extracts everything between curly braces

            topicsAndSubtopics = JSON.parse(jsonData);
          //  topicsAndSubtopics = JSON.parse(cleanedPayload);
            state.projectList[state.courseId].userStory = topicsAndSubtopics;
            state.isLoadingCourse = false;
            state.isStoryCreated = true;
          //  state.projectList[state.courseId].userStory = topicsAndSubtopics;
          //  state.assessmentQuestion=mcqTest;
         //   state.loadTakeTest = false;
          //  console.log("Parsed JSON object:", mcqTest);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            state.errorMessage = 'Server not responding, please generate once again';
          }
        } else {
          // If the payload is null, undefined, or an empty string, set an error message
          state.errorMessage = "Unable to reach Server: Please try again, sorry for the inconvenience";
          state.isLoadingCourse = false;
          state.isStoryCreated = true;
          return;
        }
    


      }) 
      .addCase(getCourseDetailsApiData.pending, (state, action) => {

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        //   console.log('Result from assesment='+action.payload.error.message);
        // state.errorMessage=action.payload.error.message;
        state.isLoadingCourse = true;
        state.isLoadingCourseOverview = true;
     //   state.isLoadingCourse = true;
       // state.isStoryCreated = false;



      })
      .addCase(getCourseTopicsFromTest.fulfilled, (state, action) => {




        let topicsAndSubtopics = [];
        topicsAndSubtopics = deepParseJson(action.payload);

        //   console.log("deepParseJson(action.payload)dee==" + topicsAndSubtopics);


        if (typeof topicsAndSubtopics === 'object' && topicsAndSubtopics !== null) {
          // Variable is an object
          console.log('topicsAndSubtopics is an object.');

          state.isLoadingCourse = false;
          state.isStoryCreated = true;
          state.userStories = [];
          state.userStories = topicsAndSubtopics;
          if(state.projectList && state.projectList[state.courseId].userStory){
            state.projectList[state.courseId].userStory = topicsAndSubtopics;
          }else{
            state.projectList[state.courseId].userStory=[];
            state.projectList[state.courseId].userStory = topicsAndSubtopics;  
          }
      
          state.messages = [];
          state.questions=[];
          state.history = [];
          state.newHistory = [];
        } else {
          // Variable is not an object
          const patternIndex = topicsAndSubtopics.indexOf("}]}", 0);
          //  const tempJson= {"studyList":[{"topic":"Introduction to Ruby","subtopics":["What is Ruby?","History of Ruby","Features of Ruby"]},{"topic":"Ruby Basics","subtopics":["Variables","Data Types","Operators","Control Structures"]},{"topic":"Ruby Objects and Classes","subtopics":["Objects","Classes","Inheritance","Modules"]},{"topic":"Ruby Methods","subtopics":["Defining Methods","Parameters","Return Values","Scope"]},{"topic":"Ruby Collections","subtopics":["Arrays","Hashes","Ranges","Iterators"]},{"topic":"Ruby File I/O","subtopics":["Reading Files","Writing Files","File Manipulation"]},{"topic":"Ruby Exceptions","subtopics":["Handling Exceptions","Raising Exceptions","Rescuing Exceptions"]},{"topic":"Ruby Modules","subtopics":["Creating Modules","Including Modules","Mixins"]},{"topic":"Ruby Gems","subtopics":["Installing Gems","Using Gems","Creating Gems"]},{"topic":"Ruby on Rails","subtopics":["Introduction to Rails","Models","Views","Controllers"]}]}
          // const jsonStringWithBracket = topicsAndSubtopics + '}';
          //  const newObject =deepParseJson(jsonStringWithBracket);
          // console.log('topicsAndSubtopics is not an object.'+newObject);


          if (patternIndex !== -1) {
            // Extract the JSON string up to the pattern index:
            const jsonString = topicsAndSubtopics.substring(0, patternIndex + 3);

            try {
              const parsedObject = deepParseJson(jsonString);
              //    console.log(parsedObject);
              //  console.log('topicsAndSubtopics is an object.'+parsedObject);

              state.isLoadingCourse = false;
              state.isStoryCreated = true;
              state.userStories = [];
              state.userStories = parsedObject;
       
              if(state.projectList[state.courseId].userStory){
                state.projectList[state.courseId].userStory =  parsedObject
              }else{
                state.projectList[state.courseId].userStory=[];
                state.projectList[state.courseId].userStory = parsedObject;
              }
          
              state.messages = [];
              state.questions=[];
              state.history = [];
              state.newHistory = [];
            } catch (error) {
              state.isLoadingCourse = false;

              console.error('Error parsing JSON:', error);
            }
          } else {
            const jsonStringWithBracket = topicsAndSubtopics + '}';
            const parsedObject = deepParseJson(jsonStringWithBracket);
            state.isLoadingCourse = false;
            state.isStoryCreated = true;
            state.userStories = [];
            state.userStories = parsedObject;
         //   state.projectList = [...state.projectList, parsedObject]
            if(state.projectList[state.courseId].userStory){
              state.projectList[state.courseId].userStory = parsedObject
            }else{
              state.projectList[state.courseId].userStory=[];
              state.projectList[state.courseId].userStory = parsedObject
            }
        
            state.messages = [];
            state.questions=[];
            state.history = [];
            state.newHistory = [];
            console.error('Pattern not found in JSON string.');
          }








        }


























   





      }) 
      .addCase(getCourseTopicsFromTest.pending, (state, action) => {

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        //   console.log('Result from assesment='+action.payload.error.message);
        // state.errorMessage=action.payload.error.message;
        state.isLoadingCourse = true;
        state.isLoadingCourseOverview = true;
     //   state.isLoadingCourse = true;
       // state.isStoryCreated = false;



      })
      .addCase(getRelatedQuestionAnswers.pending, (state, action) => {

        state.showScore=true;
        state.assessmentQuestion=[];
        state.loadTakeTest=true;
        state.errorMessage='';
  
      })
      .addCase(getRelatedQuestionAnswers.fulfilled, (state, action) => {

      //  state.newHistory=deepParseJson(action.payload);
      state.loadTakeTest=false;
        state.projectList[state.courseId].messages.forEach((obj) => {

          console.log("Inside messageRecievedLandingPageTutor======="+state.loadingMessageId, obj.id);
         // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
      //  if (obj.id === state.loadingMessageId) {
            // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
            if(obj.subRows && obj.subRows.length>0){
  
            obj.subRows.forEach((subObj) => {
  
              if(subObj.id === state.loadingMessageId){
              
              //  obj.subRows =[{ id: newId, message:state.assessmentQuestion, isMe: false, videos: []}];
                    obj.subRows.push({ id: Date.now(), message:action.payload, isMe: false, videos: []});
              }
  
            });
            
            }else{
                 obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
                 return;
            }
           
  
      //  obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
     //   }
  
     //   state.videos=[];
      });
  
      //  state.assessmentQuestion=[];
      //  state.loadTakeTest=true;
      //  state.errorMessage='';
  
      })
     .addCase(getAssesmemnt.pending, (state, action) => {

      state.showScore=true;
      state.assessmentQuestion=[];
      state.loadTakeTest=true;
      state.errorMessage='';

    })
    .addCase(getAssesmemntInCourse.pending, (state, action) => {

      state.loadTakeTest=true;
      state.errorMessage='';
  

    })
    .addCase(getAssesmemntInCourse.rejected, (state, action) => {

      state.loadTakeTest=false;
      state.errorMessage=action.error.message;
  

    })
    .addCase(getEvaluationNonStream.pending, (state, action) => {

      state.loadTakeTest=true;
      state.errorMessage='';
  

    })
    .addCase(getEvaluationNonStream.rejected, (state, action) => {

      state.loadTakeTest=false;
      state.errorMessage=action.error.message;
  

    })
    .addCase(getStudentPerformance.pending, (state, action) => {

      state.loadTakeTest=true;
   //   state.errorMessage=action.error.message;
  

    })
    .addCase(getStudentPerformance.fulfilled, (state, action) => {

      state.loadTakeTest=false;
      const email= action.meta.arg;
   

      if (action.payload) {
        const { totalMcqListLength, totalCompletedQuestionsLength } = action.payload;
        const index = state.marketProjectList.findIndex(item => item.email === email);
        if (index !== -1) {
          state.marketProjectList[index] = {
            ...state.marketProjectList[index],
           
            totalMcqListLength: totalMcqListLength,
            totalCompletedQuestionsLength: totalCompletedQuestionsLength
          };
        } else {
          // state.marketProjectList.push({
         
          
          //   totalMcqListLength: totalMcqListLength,
          //   totalCompletedQuestionsLength: totalCompletedQuestionsLength
          // });
        }
      }















      const index = state.marketProjectList.findIndex(item => item.email === email);











      if (index !== -1) {

        console.log("Inside getStudentPerformance.fulfilled======="+index);
        // state.marketProjectList[index] = {
        //   ...state.marketProjectList[index],
       
        //   totalMcqListLength: totalMcqListLength,
        //   totalCompletedQuestionsLength: totalCompletedQuestionsLength
        // };
      }
   
  

    })
    .addCase(getEvaluationNonStream.fulfilled, (state, action) => {

      state.loadTakeTest=false;
      state.newHistory=[];
      state.newHistory[0]=action.payload;
      state.projectList[state.courseId].messages.forEach((obj) => {

        console.log("Inside messageRecievedLandingPageTutor======="+state.loadingMessageId, obj.id);
       // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
    //  if (obj.id === state.loadingMessageId) {
          // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
          if(obj.subRows && obj.subRows.length>0){

          obj.subRows.forEach((subObj) => {

            if(subObj.id === state.loadingMessageId){
            
            //  obj.subRows =[{ id: newId, message:state.assessmentQuestion, isMe: false, videos: []}];
                  obj.subRows.push({ id: Date.now(), message:action.payload, isMe: false, videos: []});
                  // subObj.message.mcqList.forEach((mcq) => {
                  //   mcq.answerOptions[0].answerText = action.payload;
                  // });
            }

          });
          
          }else{
               obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
               return;
          }
         

    //  obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
   //   }

   //   state.videos=[];
    });
  

    })
    .addCase(getAssesmemntInCourse.fulfilled, (state, action) => {

      //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
      // console.log('Result from assesment'+JSON.stringify(action.payload.questions));
      //   state.assessmentQuestion=action.payload;
      //    state.loadTakeTest= false;
      state.showScore=false;
      state.errorMessage='';

    //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));


     
    let mcqTest = [];
    mcqTest = deepParseJson(action.payload);


    if (typeof mcqTest === 'object' && action.payload !== null) {
      // If the payload is already an object, use it directly
      mcqTest = action.payload;
      state.assessmentQuestion=mcqTest;
      state.loadTakeTest = false;
    //  mcqTest = deepParseJson(action.payload);
  //    console.log("Parsed JSON object:", mcqTest);
    } else if (typeof action.payload === 'string' && action.payload.trim() !== '') {
      // If the payload is a non-empty string, try to parse it as JSON
      try {
        const cleanedPayload = action.payload.replace(/```json\n|```/g, '');
        let jsonData = cleanedPayload.match(/{[\s\S]*}/)[0]; // This extracts everything between curly braces

       // topicsAndSubtopics = JSON.parse(jsonData);
        mcqTest = JSON.parse(jsonData);
        state.assessmentQuestion=mcqTest;
        state.loadTakeTest = false;
      //  console.log("Parsed JSON object:", mcqTest);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        state.errorMessage = 'Server not responding, please generate once again';
      }
    } else {
      // If the payload is null, undefined, or an empty string, set an error message
      state.errorMessage = "Unable to reach Server: Please try again, sorry for the inconvenience";
      state.loadTakeTest = false;
      return;
    }



      //    console.log("deepParseJson(action.payload)dee==11111" + mcqTest);


      // if (typeof action.payload === 'object' && action.payload !== null) {
      //   // Variable is an object
      //   //    console.log('topicsAndSubtopics is an object.');
      //   //    console.log("deepParseJson(action.payload)dee==22222" + mcqTest);

      //   state.assessmentQuestion = action.payload;
      //   //     state.takeTest= false;
      //   state.loadTakeTest = false;
      // } else if(action.payload !== null && action.payload !== undefined){ {


      //   try {
      //     // Ensure the payload is a valid JSON string
      //     const cleanedPayload = action.payload.replace(/```json\n|```/g, '');
      //     mcqTest = JSON.parse(cleanedPayload);
      //     console.log("Parsed JSON object:", mcqTest);
      //   } catch (error) {
      //     console.error("Error parsing JSON:", error);
      //     state.errorMessage = 'Server not responding, please generate once again';
      //   }

      
     
      // }else{

      //   state.errorMessage="Unable to reach Sever:Please try again,Sorry of the inconvenience";
      //   state.loadTakeTest = false;
      //   return;
      // }


      // state.messages.forEach((obj) => {

      //   //   console.log("Inside messageRecievedLandingPageTutor======="+action.payload.data.original.id);
      //    if (obj.id === action.payload.messagingId) {
      //   //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
              
      //   if(obj.subRowsRelated && obj.subRowsRelated.length>0){
      //     obj.subRows.push(obj.subRowsRelated[0]);
          
      //    // obj.subRowsRelated=[];
      //   }

       
      //    }else{

      //     obj.subRowsRelated=[];
      //    }
   
      // //   state.videos=[];
      //  });

    //  const newId = useUniqueId('mcq-')

      state.projectList[state.courseId].messages.forEach((obj) => {

       // console.log("Inside messageRecievedLandingPageTutor======="+state.loadingMessageId, obj.id);
       // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
   //   if (obj.id === state.loadingMessageId) {
          // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
          if(obj.subRows && obj.subRows.length>0){

          obj.subRows.forEach((subObj) => {

            if(subObj.id === state.loadingMessageId){
            //  console.log("Inside messageRecievedLandingPageTutor=======111"+subObj.id , state.loadingMessageId);
            //  obj.subRows =[{ id: newId, message:state.assessmentQuestion, isMe: false, videos: []}];
                  obj.subRows.push({ id: Date.now(), message:state.assessmentQuestion, isMe: false, testType: state.testName, completedQuestions:[], videos: []});
            }

          });
          
          }else{
              // obj.subRows =[{ id:Date.now(), message:state.assessmentQuestion, isMe: false, testType: state.testName, completedQuestions:[], videos: []}];
               return;
          }
         

    //  obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
   //  }

   //   state.videos=[];
    });

  //  state.projectList[state.courseId].messages = state.assessmentQuestion;
   // state.projectList[state.courseId].questions = [...state.projectList[state.courseId].questions, ...state.assessmentQuestion]


    



    })
      .addCase(getAssesmemnt.fulfilled, (state, action) => {

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        // console.log('Result from assesment'+JSON.stringify(action.payload.questions));
        //   state.assessmentQuestion=action.payload;
        //    state.loadTakeTest= false;
        state.showScore=false;
        state.errorMessage='';
       
        let mcqTest = [];
        mcqTest = deepParseJson(action.payload);

        //    console.log("deepParseJson(action.payload)dee==11111" + mcqTest);


        if (typeof mcqTest === 'object' && mcqTest !== null) {
          // Variable is an object
          //    console.log('topicsAndSubtopics is an object.');
          //    console.log("deepParseJson(action.payload)dee==22222" + mcqTest);

          state.assessmentQuestion = mcqTest;
          //     state.takeTest= false;
          state.loadTakeTest = false;
        } else {
          // Variable is not an object
          //    console.log("deepParseJson(action.payload)dee==33333" + mcqTest);
          const patternIndex = mcqTest.indexOf("}]}", 0);
          //      console.log("deepParseJson(action.payload)dee==444444" + patternIndex);
          //  const tempJson= {"studyList":[{"topic":"Introduction to Ruby","subtopics":["What is Ruby?","History of Ruby","Features of Ruby"]},{"topic":"Ruby Basics","subtopics":["Variables","Data Types","Operators","Control Structures"]},{"topic":"Ruby Objects and Classes","subtopics":["Objects","Classes","Inheritance","Modules"]},{"topic":"Ruby Methods","subtopics":["Defining Methods","Parameters","Return Values","Scope"]},{"topic":"Ruby Collections","subtopics":["Arrays","Hashes","Ranges","Iterators"]},{"topic":"Ruby File I/O","subtopics":["Reading Files","Writing Files","File Manipulation"]},{"topic":"Ruby Exceptions","subtopics":["Handling Exceptions","Raising Exceptions","Rescuing Exceptions"]},{"topic":"Ruby Modules","subtopics":["Creating Modules","Including Modules","Mixins"]},{"topic":"Ruby Gems","subtopics":["Installing Gems","Using Gems","Creating Gems"]},{"topic":"Ruby on Rails","subtopics":["Introduction to Rails","Models","Views","Controllers"]}]}
          // const jsonStringWithBracket = topicsAndSubtopics + '}';
          //  const newObject =deepParseJson(jsonStringWithBracket);
          // console.log('topicsAndSubtopics is not an object.'+newObject);


          if (patternIndex !== -1) {
            // Extract the JSON string up to the pattern index:
            //      console.log("deepParseJson(action.payload)dee==555555" + mcqTest);
            const jsonString = mcqTest.substring(0, patternIndex + 3);

            try {
              const parsedObject = deepParseJson(jsonString);
              //       console.log("deepParseJson(action.payload)dee==66666" + mcqTest);
              //    console.log(parsedObject);
              //  console.log('topicsAndSubtopics is an object.'+parsedObject);

              state.assessmentQuestion = parsedObject;
              //       console.log("deepParseJson(action.payload)dee==777777" + mcqTest);
              state.loadTakeTest = false;
            } catch (error) {
              state.isLoadingCourse = false;
              //    console.log("deepParseJson(action.payload)dee==888888" + mcqTest);

              console.error('Error parsing JSON:', error);
            }
          } else {
            //       console.log("deepParseJson(action.payload)dee==9999999" + mcqTest);
            const jsonStringWithBracket = mcqTest + '}';
            const parsedObject = deepParseJson(jsonStringWithBracket);
            state.assessmentQuestion = parsedObject;
            state.loadTakeTest = false;
            //       console.log("deepParseJson(action.payload)dee==101010101" + mcqTest);
          }


        }


        // state.messages.forEach((obj) => {

        //   //   console.log("Inside messageRecievedLandingPageTutor======="+action.payload.data.original.id);
        //    if (obj.id === action.payload.messagingId) {
        //   //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
                
        //   if(obj.subRowsRelated && obj.subRowsRelated.length>0){
        //     obj.subRows.push(obj.subRowsRelated[0]);
            
        //    // obj.subRowsRelated=[];
        //   }

         
        //    }else{

        //     obj.subRowsRelated=[];
        //    }
     
        // //   state.videos=[];
        //  });

        state.questions.forEach((obj) => {

          console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
         // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
        if (obj.id === state.loadingMessageId) {
            // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
            if(obj.subRows && obj.subRows.length>0){
              obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
            }else{
                 obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
            }
           

      
        }

     //   state.videos=[];
      });

      state.projectList[state.courseId].questions = [];
      state.projectList[state.courseId].questions = [...state.projectList[state.courseId].questions, ...state.questions]


      



      })
      .addCase(getAssesmemnt.rejected, (state, action) => {

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
          console.log('Result from assesment='+JSON.stringify(action.error.message));
        state.errorMessage = action.error.message;
        state.loadTakeTest = false;


      })
      .addCase(getSprintStories.pending, (state, action) => {

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        //   console.log('Result from assesment='+action.payload.error.message);
        //  state.errorMessage=action.payload.error.message;
        state.loadingUserStories = true;


      })
      .addCase(loadStateFromFirebaseLogin.pending, (state, action) => {

        state.isLoading=true;
      })
      .addCase(loadStateFromFirebaseLogin.fulfilled, (state, action) => {

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        //   console.log('Result from assesment='+action.payload.error.message);
        // state.errorMessage=action.payload.error.message;
        state.isLoading=false;

        if (state.createdCourses &&  state.createdCourses.length > 0) {
          state.userStories = state.createdCourses[state.courseId];
          //   state.messages=[];
          //   state.history=[];
          //   state.newHistory=[];
          state.messages = (state.createdCourses[state.courseId].messages) ? (state.createdCourses[state.courseId].messages) : [];

        }


      })
      .addCase(loadStateFromFirebase.pending, (state, action) => {

        state.isLoading=true;
      })
      .addCase(loadStateFromFirebase.fulfilled, (state, action) => {

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        //   console.log('Result from assesment='+action.payload.error.message);
        // state.errorMessage=action.payload.error.message;
        state.isLoading=false;

        if (state.createdCourses &&  state.createdCourses.length > 0) {
          state.userStories = state.createdCourses[state.courseId];
          //   state.messages=[];
          //   state.history=[];
          //   state.newHistory=[];
          state.messages = (state.createdCourses[state.courseId].messages) ? (state.createdCourses[state.courseId].messages) : [];

        }


      })
      .addCase(fetchYoutbeVideosApi.fulfilled, (state, action) => {

        state.isVideo = true;
        state.loadTakeTest = false;

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        //   console.log('Result from assesment='+action.payload.error.message);
        // state.errorMessage=action.payload.error.message;

      // state.videos=action.payload.items;


      })
      .addCase(fetchYoutbeVideosApi.pending, (state, action) => {

        state.loadTakeTest = true;



      })
      .addCase(fetchYoutbeVideosTranscript.fulfilled, (state, action) => {

       state.transcript=[];
        state.transcript=action.payload;



      //   state.messages.forEach((obj) => {

      //     //  console.log("Inside messageRecievedLandingPageTutor======="+state.newHistory[0]);
      //     if (obj.id === state.loadingMessageId) {
  
      //       //    console.log("Inside messageRecievedLandingPageTutor=+++++++"+state.newHistory[0]);
  
      //       obj.transcript = action.payload;
      //       //  obj.videos=state.videos;
      //       //   obj.subRows = [{id:obj.id,message:state.newHistory[0].replace(emptyJsonPattern, ' '),isMe:false}];
  
      //       //   obj.subRows = [{id:obj.id,message:state.newHistory[0],isMe:false}];
      //     }
  
      //  //   state.videos=[];
      //   });









      //  state.isVideo = true;
  //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));

    //    state.sharedUrl=action.payload;

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        //   console.log('Result from assesment='+action.payload.error.message);
        // state.errorMessage=action.payload.error.message;

      // state.videos=action.payload.items;


      }) .addCase(getSpeechSynthesize.fulfilled, (state, action) => {

          
      state.projectList[state.courseId].messages.forEach((obj) => {
  
  
          
        if (obj.id === state.loadingMessageId) {
     //    console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload);
             
        
   
          obj.subRows = [...obj.subRows,{ id: obj.id, message:'', isMe: false, audioClip:action.payload,file:obj.subRows[0].file,
            videoLink:obj.subRows[0].videoLink }];
           // const groupedArray = groupBySubRowId(yourArray);
  
        }
  
     //   state.videos=[];
      });
  
       
     //   state.audioClip = action.payload;
        state.isLoadingSpeech=false;
  
      }).addCase(getSpeechSynthesize.rejected, (state, action) => {

     
         state.isLoadingSpeech=false;
  
       }).addCase(getSpeechSynthesize.pending, (state, action) => {

        
         state.isLoadingSpeech=true;
    
       }).addCase(generatePresignedUrl.fulfilled, (state, action) => {

        state.sharedId=action.payload;


        state.showScore=true;
        state.assessmentQuestion=[];
        state.loadTakeTest=true;
        state.errorMessage='';
    
       })
       .addCase(generateQuestionFromTopic.pending, (state, action) => {

        


        state.showScore=true;
        state.assessmentQuestion=[];
        state.loadTakeTest=true;
        state.errorMessage='';
    
       })
      .addCase(generateQuestionFromTopic.fulfilled, (state, action) => {

        //  console.log("Inside payload parsed json=====" + JSON.stringify(action.payload));
        // console.log('Result from assesment'+JSON.stringify(action.payload.questions));
        //   state.assessmentQuestion=action.payload;
        //    state.loadTakeTest= false;
        state.showScore=false;
        state.errorMessage='';
       
        let mcqTest = [];
        mcqTest = deepParseJson(action.payload);
  
        //    console.log("deepParseJson(action.payload)dee==11111" + mcqTest);
  
  
        if (typeof mcqTest === 'object' && mcqTest !== null) {
          // Variable is an object
          //    console.log('topicsAndSubtopics is an object.');
          //    console.log("deepParseJson(action.payload)dee==22222" + mcqTest);
  
          state.assessmentQuestion = mcqTest;
          //     state.takeTest= false;
          state.loadTakeTest = false;
        } 
        
        else {
          // Variable is not an object
          //    console.log("deepParseJson(action.payload)dee==33333" + mcqTest);
          const patternIndex = mcqTest.indexOf("}]}", 0);
          //      console.log("deepParseJson(action.payload)dee==444444" + patternIndex);
          //  const tempJson= {"studyList":[{"topic":"Introduction to Ruby","subtopics":["What is Ruby?","History of Ruby","Features of Ruby"]},{"topic":"Ruby Basics","subtopics":["Variables","Data Types","Operators","Control Structures"]},{"topic":"Ruby Objects and Classes","subtopics":["Objects","Classes","Inheritance","Modules"]},{"topic":"Ruby Methods","subtopics":["Defining Methods","Parameters","Return Values","Scope"]},{"topic":"Ruby Collections","subtopics":["Arrays","Hashes","Ranges","Iterators"]},{"topic":"Ruby File I/O","subtopics":["Reading Files","Writing Files","File Manipulation"]},{"topic":"Ruby Exceptions","subtopics":["Handling Exceptions","Raising Exceptions","Rescuing Exceptions"]},{"topic":"Ruby Modules","subtopics":["Creating Modules","Including Modules","Mixins"]},{"topic":"Ruby Gems","subtopics":["Installing Gems","Using Gems","Creating Gems"]},{"topic":"Ruby on Rails","subtopics":["Introduction to Rails","Models","Views","Controllers"]}]}
          // const jsonStringWithBracket = topicsAndSubtopics + '}';
          //  const newObject =deepParseJson(jsonStringWithBracket);
          // console.log('topicsAndSubtopics is not an object.'+newObject);
  
  
          if (patternIndex !== -1) {
            // Extract the JSON string up to the pattern index:
            //      console.log("deepParseJson(action.payload)dee==555555" + mcqTest);
            const jsonString = mcqTest.substring(0, patternIndex + 3);
  
            try {
              const parsedObject = deepParseJson(jsonString);
              //       console.log("deepParseJson(action.payload)dee==66666" + mcqTest);
              //    console.log(parsedObject);
              //  console.log('topicsAndSubtopics is an object.'+parsedObject);
  
              state.assessmentQuestion = parsedObject;
              //       console.log("deepParseJson(action.payload)dee==777777" + mcqTest);
              state.loadTakeTest = false;
            } catch (error) {
              state.isLoadingCourse = false;
              //    console.log("deepParseJson(action.payload)dee==888888" + mcqTest);
  
              console.error('Error parsing JSON:', error);
            }
          } else {
            //       console.log("deepParseJson(action.payload)dee==9999999" + mcqTest);
            const jsonStringWithBracket = mcqTest + '}';
            const parsedObject = deepParseJson(jsonStringWithBracket);
            state.assessmentQuestion = parsedObject;
            state.loadTakeTest = false;
            //       console.log("deepParseJson(action.payload)dee==101010101" + mcqTest);
          }
  
  
        }
  
  
        // state.messages.forEach((obj) => {
  
        //   //   console.log("Inside messageRecievedLandingPageTutor======="+action.payload.data.original.id);
        //    if (obj.id === action.payload.messagingId) {
        //   //   console.log("Inside messageRecievedLandingPageTutor=+++++++"+action.payload.data.original.subRows[0].message);
                
        //   if(obj.subRowsRelated && obj.subRowsRelated.length>0){
        //     obj.subRows.push(obj.subRowsRelated[0]);
            
        //    // obj.subRowsRelated=[];
        //   }
  
         
        //    }else{
  
        //     obj.subRowsRelated=[];
        //    }
     
        // //   state.videos=[];
        //  });

        state.projectList[state.courseId].questions=[...state.projectList[state.courseId].questions, { id: Date.now(),message:"##### Test Topic", subRows:[{ id: Date.now(), message:state.assessmentQuestion, isMe: false}], isMe: false, }];
  
    //     state.projectList[state.courseId].questions.forEach((obj) => {
  
    //       console.log("Inside messageRecievedLandingPageTutor======="+state.loadingMessageId,obj.id);
    //      // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
    //     if (obj.id === state.loadingMessageId) {
    //         // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
    //         if(obj.subRows && obj.subRows.length>0){
            
    //         }else{
    //              obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
    //         }
           
  
    //     obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
    //     }else{

       

    //           //   console.log("Inside sendMessageToChatLandingBox" + action.payload)
    //      const responseMessage = {
    //       id: generateRandomId(),
    //       message:state.assessmentQuestion,
    //       isMe: true
    //     };
    //     obj.subRows.push(responseMessage);
    //   //  state.tutorContext=[...state.tutorContext,action.payload];
    // //  state.questions=[];
  
    //  //   state.questions = [...state.questions, responseMessage];
    //  //   state.isLoading = true;
    //     state.loadingMessageId = responseMessage.id;
    //   //  state.videoId = action.payload;
    //     state.isFromRelatedQuestion=false;
    //     }
  
    //  //   state.videos=[];
    //   });
  
    //  state.projectList[state.courseId].messages = state.assessmentQuestion;
     // state.projectList[state.courseId].questions = [...state.projectList[state.courseId].questions, ...state.assessmentQuestion]
  
  
  
  
  
  
      })
     
     
     
      .addCase(getStripePaymentCheckout.fulfilled, (state, action) => {
     
      //  state.paymentCompleted = true;
        state.sprintsStories=action.payload
        window.location.href = state.sprintsStories.url;;

    //    state.isStoryCreated = true;
     //   state.userStories = [...state.userStories,serialNumbersWithText]
      })
      .addCase(createStudentAndShare.fulfilled, (state, action) => {
     
        const {name,email,role} = action.meta.arg;
        state.isLoading=false
        state.marketProjectList=[...state.marketProjectList,{name,email,role}];
       
  
      //    state.isStoryCreated = true;
       //   state.userStories = [...state.userStories,serialNumbersWithText]
        })
        .addCase(createStudentAndShare.pending, (state, action) => {
  
          state.isLoading=true
      
         
    
        //    state.isStoryCreated = true;
         //   state.userStories = [...state.userStories,serialNumbersWithText]
          })
          .addCase(createStudentAndShare.rejected, (state, action) => {
     
        
            state.isLoading=false
         
           
      
          //    state.isStoryCreated = true;
           //   state.userStories = [...state.userStories,serialNumbersWithText]
            }).
      addCase(getChatGPTApiEvaluateData.pending, (state, action) => {
     
        //  state.paymentCompleted = true;
          state.isLoading=true
        //  window.location.href = state.sprintsStories.url;;
  
      //    state.isStoryCreated = true;
       //   state.userStories = [...state.userStories,serialNumbersWithText]
        })
      
      .addCase(getChatGPTApiEvaluateData.fulfilled, (state, action) => {
     
      //   state.projectList[state.courseId].messages.forEach((obj) => {

      //     if (obj.id === state.courseUniqueId) {

      //       obj.subRows.push({ id: obj.id, message:state.scoreTopic+state.newHistory[0], isMe: false, testResult:state.yourArray,testScore:state.mcqString});
       
          
           
  
       
      // }
         
         

      // });





      state.projectList[state.courseId].messages.forEach((obj) => {

        console.log("Inside messageRecievedLandingPageTutor======="+state.loadingMessageId, obj.id);
       // obj.subRows =[{ id: obj.id, message:state.newHistory[0], isMe: false, videos: videosToEmbed}];
    //  if (obj.id === state.loadingMessageId) {
          // obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
          if(obj.subRows && obj.subRows.length>0){

          obj.subRows.forEach((subObj) => {

            if(subObj.id === state.loadingMessageId){
            
            //  obj.subRows =[{ id: newId, message:state.assessmentQuestion, isMe: false, videos: []}];
                  obj.subRows.push({ id: Date.now(), message:state.assessmentQuestion, isMe: false, videos: []});
            }

          });
          
          }else{
               obj.subRows =[{ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []}];
               return;
          }
         

    //  obj.subRows.push({ id: obj.id, message:state.assessmentQuestion, isMe: false, videos: []});
   //   }

   //   state.videos=[];
    });














  
          state.isLoading = false;
       //   state.userStories = [...state.userStories,serialNumbersWithText]
        })
      .addCase(getSprintStories.fulfilled, (state, action) => {

        let sprintsStory = [];
        sprintsStory = deepParseJson(action.payload);

        console.log("deepParseJson(action.payload)dee==11111" + sprintsStory);


        if (typeof sprintsStory === 'object' && sprintsStory !== null) {
          // Variable is an object
          console.log('topicsAndSubtopics is an object.');
          console.log("deepParseJson(action.payload)dee==22222" + sprintsStory);

          state.sprintsStories = sprintsStory;
          //     state.takeTest= false;
          //  state.loadTakeTest= false;
        } else {
          // Variable is not an object
          //  console.log("deepParseJson(action.payload)dee==33333" + mcqTest);
          const patternIndex = sprintsStory.indexOf("}]}", 0);
          //     console.log("deepParseJson(action.payload)dee==444444" + patternIndex);
          //  const tempJson= {"studyList":[{"topic":"Introduction to Ruby","subtopics":["What is Ruby?","History of Ruby","Features of Ruby"]},{"topic":"Ruby Basics","subtopics":["Variables","Data Types","Operators","Control Structures"]},{"topic":"Ruby Objects and Classes","subtopics":["Objects","Classes","Inheritance","Modules"]},{"topic":"Ruby Methods","subtopics":["Defining Methods","Parameters","Return Values","Scope"]},{"topic":"Ruby Collections","subtopics":["Arrays","Hashes","Ranges","Iterators"]},{"topic":"Ruby File I/O","subtopics":["Reading Files","Writing Files","File Manipulation"]},{"topic":"Ruby Exceptions","subtopics":["Handling Exceptions","Raising Exceptions","Rescuing Exceptions"]},{"topic":"Ruby Modules","subtopics":["Creating Modules","Including Modules","Mixins"]},{"topic":"Ruby Gems","subtopics":["Installing Gems","Using Gems","Creating Gems"]},{"topic":"Ruby on Rails","subtopics":["Introduction to Rails","Models","Views","Controllers"]}]}
          // const jsonStringWithBracket = topicsAndSubtopics + '}';
          //  const newObject =deepParseJson(jsonStringWithBracket);
          // console.log('topicsAndSubtopics is not an object.'+newObject);


          if (patternIndex !== -1) {
            // Extract the JSON string up to the pattern index:
            //  console.log("deepParseJson(action.payload)dee==555555" + mcqTest);
            const jsonString = sprintsStory.substring(0, patternIndex + 3);

            try {
              const parsedObject = deepParseJson(jsonString);
              //   console.log("deepParseJson(action.payload)dee==66666" + mcqTest);
              //    console.log(parsedObject);
              //  console.log('topicsAndSubtopics is an object.'+parsedObject);

              state.sprintsStories = parsedObject;
              //    console.log("deepParseJson(action.payload)dee==777777" + mcqTest);
              state.loadTakeTest = false;
            } catch (error) {
              state.isLoadingCourse = false;
              //   console.log("deepParseJson(action.payload)dee==888888" + mcqTest);

              console.error('Error parsing JSON:', error);
            }
          } else {
            //    console.log("deepParseJson(action.payload)dee==9999999" + mcqTest);
            const jsonStringWithBracket = sprintsStory + '}';
            const parsedObject = deepParseJson(jsonStringWithBracket);
            state.sprintsStories = parsedObject;
            state.loadTakeTest = false;
            //   console.log("deepParseJson(action.payload)dee==101010101" + mcqTest);
          }


        }



      })

  },
})

export const {
  updateReply,
  toggleSidebar,
  toggleMobileSidebar,
  toggleNewMessageDialog,
  updateSelectedCategory,
  sendMessageToChatBoxProjectTutor,
  setCourseLodingIcon,
  putProject,
  
  deleteProject,
  messageRecievedLandingPageTutor,
  sendMessageToChatLandingBox,
  setCourseId,
  updateCourseId,
  updateHistoryLandinPageTutor,
  messageRecievedThreadTutor,
  updateThreadTopicPart,
  loadFromFirestore,
  setSearchCourseList,
  deleteStateonSignOut,
  updateTakeTest,
  switchTutor,
  setYoutubeVideoLink,
  setVideos,
  setDocumentId,
  updateRemovedRowLandingpagetutor,
  runCodeDynamically,
  updateCourseUniqueId,
  shareLearning,
  setShowVideo,
  setVideoToDisplay,
  setSelectedLanguage,
  setShowScore,
  setOverviewState,
  setVideoDemanded,
  setSharedId,
  setSharedUrl,
  usersToDisplay,
  putMarketplaceProject,
  messageReceivedMarketplaceTutor,
  setCourseStep,
  messageRecievedAssessment,
  setTutoContext,
  setScoreForTopic,
  setTopicTest,
  resetQuestions,
  editMessageRow,
  setRowBack,
  updateUserStoryShared,
  sharedByMe,
  setTestType,
  setUserStoryUpdate,
  setLoadingMessagingId,
  setRelatedQuestions,
  hideTutor,setAssesmentFromPdf,
  parsedQuestions,setQuestionScore,
  setCurrentQuestionAsked,updateProjectIndex,
  sendMessageToTestLandingBox,setAnswerGivenByUser,
  removeOptedAnswer,setSelectedQuestions,removeRow,
  setOptedAnswer,setExtractedData,updateCanvasDataToRow,
  clearQuestions,messageRecievedLearnTestFrmTutor,updateProjectData,
  hideEvaluteFlag,clearYourArray,setOfflineAssessment,messageRecievedStudentCourse,
  setHideTutor,clearTutorContext,setPlanData,setDataAfterLogin,
  setPercentTestLevel,setSharedCourses,setStudentsScores,fetchSelefStudyTest,
  setRelatedAssessment,setCourseName,setCompletedQuestions,setCourseFromLocal,
  editQuestionsRow,addItem,clearNewHistory,setImportedCourseId,setProjectDataFromLocal,
  setAssessmentRowBack,shareIndividualQuestion,setContenType,clearMessages,setTestFromLocal,setCanvasDataToRow,
  mergeSubRows,updateMCQQuestions,mergeAllSubRows,setQuestionsStudent,fetchQuestionsStudent,setShareToMeAssessment

} = liveCodeSlice.actions


export default liveCodeSlice.reducer
