import ApiServiceNew from "./ApiServiceNew"

export async function apiSignIn(data) {
    return ApiServiceNew.fetchData({
        url: '/gaze_tutor/signin',
        method: 'post',
        data,
    })
}


export async function apiSignUpNew(data) {
    
    return ApiServiceNew.fetchData({
        url: '/signUp',
        method: 'post',
        data,
    })
}


export async function apiSendCoursesToSusers(data) {
    
    return ApiServiceNew.fetchData({
        url: '/sendSharedCourseUsers',
        method: 'post',
        data,
    })
}


export async function apiCreateStudentAndShare(data) {
    
    return ApiServiceNew.fetchData({
        url: '/createUserWithPhoneNumber',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiServiceNew.fetchData({
        url: '/gaze_tutor/signout',
        method: 'post',
        data,
    })
}



export async function apiForgotPassword(data) {
    return ApiServiceNew.fetchData({
        url: '/gaze_tutor/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiServiceNew.fetchData({
        url: '/resetpassword',
        method: 'post',
        data,
    })
}
